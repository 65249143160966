/* eslint-disable max-len */
import React from "react";

import { ResponsiveImage } from "@quintype/components";

const Whatsapp = () => (
  <ResponsiveImage
    slug="thequint/2024-07/8e17a3c7-3794-43cc-86b0-fc3168863ad3/whatsapp.png"
    type="image/webp"
    alt="WhatsApp"
    aspectRatio={[1, 1]}
    defaultWidth={20}
    eager={false}
    imgParams={{ auto: ["format", "compress"] }}
    imageCDN="media.assettype.com"
  />
);

export default Whatsapp;
