/* eslint-disable max-len */
import React from "react";

import { ResponsiveImage } from "@quintype/components";

import "./twitter.m.css";

const Twitter = () => (
  <ResponsiveImage
    slug="thequint/2024-07/3047da3b-8a9f-4b3e-9330-42ebc3a97044/twitter.png"
    type="image/webp"
    alt="Twitter"
    aspectRatio={[1, 1]}
    defaultWidth={20}
    eager={false}
    imgParams={{ auto: ["format", "compress"] }}
    imageCDN="media.assettype.com"
  />
);

export default Twitter;
