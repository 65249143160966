/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { string, number, object, any, bool } from "prop-types";
import { Link, WithLazy } from "@quintype/components";
import { isBlackListerStory } from "../../utils/utils";
import DfpAd from "../../dfp-ad";
import StickyBanner from "../../molecules/sticky-banner";

import "./ads.m.css";

const adKeys = {
  "home-page": "home",
  "story-page": "article",
  "section-page": "section",
  "tag-page": "section",
  quint: "tq",
  "quint-hindi": "hq",
  fit: "fit",
};

const AdSlotBase = ({
  id,
  publisherName,
  isHome,
  pageType,
  type = "desktop",
  isActivePlan,
  removeAdDelay,
  env,
  ...props
}) => {
  if (isActivePlan) return null;
  if (pageType === "membership-page" || pageType === "campaign-page") return null;

  let slotId = `${adKeys[publisherName]}_${type}_${adKeys[pageType]}_${id}`;

  if (isHome) {
    slotId = `${adKeys[publisherName]}_${type}_home_${id}`;
    return (
      <DfpAd
        adtype={slotId}
        removeAdDelay={removeAdDelay}
        pageType={pageType}
        env={env}
        publisherName={publisherName}
        {...props}
      />
    );
  }

  const [temp, setTemp] = useState(false);

  useEffect(() => {
    setTemp(true);
    setTimeout(() => {
      setTemp(false);
    }, 100);
  }, [props.isDarkTheme]);

  return (
    <WithLazy margin="300px">
      {() => (
        <div>
          {!temp ? (
            <DfpAd
              adtype={slotId}
              removeAdDelay={removeAdDelay}
              pageType={pageType}
              env={env}
              publisherName={publisherName}
              {...props}
            />
          ) : null}
        </div>
      )}
    </WithLazy>
  );
};

AdSlotBase.propTypes = {
  isActivePlan: number,
  id: string,
  pageType: string,
  publisherName: string,
  type: string,
  removeAdDelay: string,
  isHome: bool,
  isDarkTheme: bool,
  env: string,
};

const mapStateToProps = (state) => ({
  isActivePlan: get(state, ["userReducer", "isActivePlan"], 0),
  removeAdDelay: get(state, ["userReducer", "removeAdDelay"]),
  pageType: get(state, ["qt", "pageType"]),
  publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null),
  isDarkTheme: get(state, ["settingsReducer", "isDarkTheme"], false),
  env: get(state, ["qt", "config", "publisher-attributes", "env"], "production"),
});

const AdSlot = connect(mapStateToProps, null)(AdSlotBase);

const AdFreeBase = ({ isActivePlan, children, member }) => {
  if (member && isActivePlan) return null;
  return <>{children}</>;
};

AdFreeBase.propTypes = {
  isActivePlan: number,
  children: any,
  member: object,
};

const mapStateToProps1 = (state) => ({
  isActivePlan: get(state, ["userReducer", "isActivePlan"], 0),
  member: state.member,
});

const AdFree = connect(mapStateToProps1, null)(AdFreeBase);

const proptypeSet1 = {
  mobile: string,
  desktop: string,
  story: object,
  section: object,
};

const proptypeSet2 = {
  id: string,
  story: object,
  type: string,
  section: object,
};

const AdText = ({ story = null, authorId = null }) => {
  let blackListedStory = false;
  if (story || authorId) {
    blackListedStory = isBlackListerStory(story.slug) || isBlackListerStory(`${authorId}`);
  }
  return (
    <div styleName="top-ad-text">
      <span styleName="ad-text">ADVERTISEMENT</span>
      {!blackListedStory && (
        <Link href="/plan-selection?utm_source=remove_ad&utm_medium=remove_ad_button" styleName="remove-ad-text">
          REMOVE AD
        </Link>
      )}
    </div>
  );
};

AdText.propTypes = {
  story: object,
  authorId: string,
};
const QuintTopResponsiveContainer = ({ desktop, mobile, campaign = false, section = {}, tag = {} }) => {
  return (
    <AdFree>
      <div
        id="quint-top-ad"
        styleName={campaign ? `quint-top-ad-width ad quint-top-campaign-ad` : `quint-top-ad-width ad`}
      >
        <AdText story={tag} />
        <div styleName={campaign ? `ad-container quint-top-campaign-ad` : `ad-container quint-top-ad`}>
          <div styleName="desktop">
            <AdSlot id={desktop} type="desktop" section={section} />
          </div>
          <div styleName="mobile">
            <AdSlot id={mobile} type="mobile" section={section} />
          </div>
        </div>
      </div>
    </AdFree>
  );
};

QuintTopResponsiveContainer.propTypes = {
  mobile: string,
  desktop: string,
  story: object,
  campaign: bool,
  section: object,
  tag: string,
};

const QuintTopResponsiveCampaignContainer = ({ desktop, mobile, campaign = false }) => {
  return (
    <AdFree>
      <div styleName={campaign ? `quint-top-ad-width ad quint-top-campaign-ad` : `quint-top-ad-width ad`}>
        <AdText />
        <div styleName={campaign ? `ad-container quint-top-ad quint-top-campaign-ad` : `ad-container quint-top-ad`}>
          <div styleName="desktop">
            <AdSlot id={desktop} type="desktop" />
          </div>
        </div>
      </div>
    </AdFree>
  );
};

QuintTopResponsiveCampaignContainer.propTypes = {
  mobile: string,
  desktop: string,
  campaign: bool,
};

const Standard337x280Container = ({ id, type, story = {}, campaign = false, section = {} }) => {
  const disableAds = get(story.metadata, ["story-attributes", "disableads", "0"], "no") === "yes";
  return disableAds ? null : (
    <AdFree>
      <div
        styleName={campaign ? `standard-337x280-width ad campaign` : `standard-337x280-width ad`}
        className="standard-337x280-width"
      >
        <AdText story={story} />
        <div styleName="ad-container standard-337x280" className="standard-337x280">
          <AdSlot id={id} story={story} type={type} section={section} />
        </div>
      </div>
    </AdFree>
  );
};

Standard337x280Container.propTypes = {
  id: string,
  story: object,
  type: string,
  campaign: bool,
  section: object,
};

const Responisive337x280Container = ({ desktop, mobile, story = {}, section = {} }) => {
  return (
    <AdFree>
      <div styleName="standard-337x280-width ad">
        <AdText />
        <div styleName="ad-container standard-337x280" className="standard-337x280">
          <div styleName="desktop">
            <AdSlot id={desktop} story={story} section={section} type="desktop" />
          </div>
          <div styleName="mobile">
            <AdSlot id={mobile} story={story} section={section} type="mobile" />
          </div>
        </div>
      </div>
    </AdFree>
  );
};

Responisive337x280Container.propTypes = proptypeSet1;

const Standard300x260Container = ({
  id,
  campaign,
  hideDesktop = false,
  section = {},
  custom = true,
  tag = {},
  authorId = null,
}) => {
  const [styles, setStyles] = useState("");
  useEffect(() => {
    if (hideDesktop) {
      setStyles("standard-300x260-width ad hide-desktop");
    } else {
      if (custom) {
        setStyles("standard-300x260-width standard-300x260-width-modified ad");
      } else {
        setStyles("standard-300x260-width ad");
      }
    }
  }, []);
  return (
    <AdFree>
      <div styleName={styles}>
        <AdText story={tag} authorId={authorId} />
        <div
          styleName={campaign ? `ad-container standard-300x260 campaign` : `ad-container standard-300x260`}
          className="standard-300x260"
        >
          <AdSlot id={id} section={section} />
        </div>
      </div>
    </AdFree>
  );
};

Standard300x260Container.propTypes = {
  id: string,
  campaign: bool,
  hideDesktop: bool,
  section: object,
  custom: bool,
  tag: object,
  authorId: string,
};

const Standard337x600Container = ({ id, story = {}, section = {} }) => {
  return (
    <AdFree>
      <div styleName="standard-337x600-width ad desktop">
        <AdText />
        <div styleName="ad-container standard-337x600" className="standard-337x600">
          <AdSlot id={id} story={story} type="desktop" />
        </div>
      </div>
    </AdFree>
  );
};

Standard337x600Container.propTypes = proptypeSet2;

const StoryTopResponsiveContainer = ({ desktop, mobile, story, campaign = false }) => {
  const disableAds = get(story.metadata, ["story-attributes", "disableads", "0"], "no") === "yes";
  return disableAds ? null : (
    <AdFree>
      <div styleName="story-top-ad-width ad">
        <AdText story={story} />
        <div
          styleName={campaign ? `ad-container story-top-ad quint-story-top-campaign-ad` : `ad-container story-top-ad`}
        >
          <div styleName="desktop">
            <AdSlot id={desktop} story={story} type="desktop" />
          </div>
          <div styleName="mobile">
            <AdSlot id={mobile} story={story} type="mobile" />
          </div>
        </div>
      </div>
    </AdFree>
  );
};

StoryTopResponsiveContainer.propTypes = {
  mobile: string,
  desktop: string,
  story: object,
  campaign: bool,
};

const StoryResponsiveContainer = ({ desktop, mobile, story }) => {
  const disableAds = get(story.metadata, ["story-attributes", "disableads", "0"], "no") === "yes";
  return disableAds ? null : (
    <AdFree>
      <div styleName="story-content-width ad">
        <AdText story={story} />
        <div styleName="ad-container story-content">
          <div styleName="desktop">
            <AdSlot id={desktop} story={story} type="desktop" />
          </div>
          <div styleName="mobile">
            <AdSlot id={mobile} story={story} type="mobile" />
          </div>
        </div>
      </div>
    </AdFree>
  );
};

const OutOfPageAd1 = () => {
  return (
    <AdFree>
      <AdSlot id="out_of_page_1" isHome />
    </AdFree>
  );
};

const OutOfPageAd2 = () => {
  return (
    <AdFree>
      <AdSlot id="out_of_page_2" isHome />
    </AdFree>
  );
};

const McanvasAd = () => {
  return (
    <AdFree>
      <AdSlot id="mcanvas" isHome />
    </AdFree>
  );
};

const VidoomyAd = () => {
  return (
    <AdFree>
      <AdSlot id="vidoomy" isHome />
    </AdFree>
  );
};

StoryResponsiveContainer.propTypes = proptypeSet1;

export {
  QuintTopResponsiveContainer,
  QuintTopResponsiveCampaignContainer,
  Standard337x280Container,
  Standard337x600Container,
  Standard300x260Container,
  StoryTopResponsiveContainer,
  StoryResponsiveContainer,
  Responisive337x280Container,
  AdSlot,
  AdFree,
  StickyBanner,
  OutOfPageAd1,
  OutOfPageAd2,
  McanvasAd,
  VidoomyAd,
};
