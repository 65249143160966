/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { string, number, func } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { setPostLoginPayment } from "../../helper/actions";
import translate from "../../translate";

import "./ad-blocker-popup.m.css";

const AdBlockerPopup = ({ publisherName, isActivePlan, setPostLoginPayment }) => {
  if (isActivePlan !== 0) {
    return null;
  }
  const [screen, setScreen] = useState(1);
  const [showdemo, setShowDemo] = useState(false);
  const [selectedAdBlock, setSelectedAdBlock] = useState(null);
  const demoGifs = [
    "https://www.thequint.com/quintlab/ad-blocker/chrome-adblock-plus.mp4?autoplay=1&loop=1&autopause=0&muted=1",
    "https://www.thequint.com/quintlab/ad-blocker/chrome-adblock.mp4?autoplay=1&loop=1&autopause=0&muted=1",
    "https://www.thequint.com/quintlab/ad-blocker/chrome-ublock.mp4?autoplay=1&loop=1&autopause=0&muted=1",
    "https://www.thequint.com/quintlab/ad-blocker/chrome-ublock-origin.mp4?autoplay=1&loop=1&autopause=0&muted=1",
  ];
  const adBlockerName = ["AdBlock Plus", "AdBlock", "UBlock", "UBlock Origin"];

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const captureSignInClick = () => {
    setPostLoginPayment(true, "ad-blocker");
  };

  const captureAdBlockClick = (index) => {
    setShowDemo(true);
    setSelectedAdBlock(index);
  };

  const capturePrevoiousclick = () => {
    setShowDemo(false);
    setScreen(1);
  };

  const renderAdBlockIcon = () => {
    if (selectedAdBlock === 0) {
      return (
        <img src="https://media.assettype.com/thequint/2023-07/e076a9ea-be4d-421a-9370-c686cfe9225a/adblock_plus.svg" />
      );
    } else if (selectedAdBlock === 1) {
      return (
        <img src="https://media.assettype.com/thequint/2023-07/c61ab959-4761-46a4-b620-a1d3f4edbc30/ad_block.svg" />
      );
    } else if (selectedAdBlock === 2) {
      return <img src="https://media.assettype.com/thequint/2023-07/25a8ab62-7517-445c-ba49-00e99fba188b/ublock.svg" />;
    }

    return (
      <img src="https://media.assettype.com/thequint/2023-07/74073bd3-acea-471b-b731-4bb58f628ff7/ublock_origin.svg" />
    );
  };

  return (
    <>
      <div styleName="overlay"></div>
      <div styleName={showdemo ? "adblocker increased-height" : "adblocker"} className="adblocker-popup">
        <div styleName="blocker-wrapper">
          <div>
            <img
              src="https://media.assettype.com/thequint/2023-09/58cfa675-87b6-4b87-b451-7460804b4298/m1.png"
              style={{ width: "286px", height: "286px" }}
            />
          </div>

          <div styleName="rhs">
            {screen === 1 ? (
              <>
                <div styleName="main-heading">
                  {translate(publisherName, "membership_widget_pre_heading")}{" "}
                  {translate(publisherName, "will_you_be_one")}
                </div>
                <div styleName="sub-heading">
                  <div>{translate(publisherName, "ad_blocker_on")}</div>
                  <div>{translate(publisherName, "fund_our_journalism")}</div>
                </div>
                <div styleName="allow-ads">{translate(publisherName, "show_us_support")}</div>
                <div>
                  <div styleName="btns">
                    <div styleName="button" onClick={() => setScreen(2)}>
                      {translate(publisherName, "allow_ads")}
                    </div>
                    <a styleName="button" href="/plan-selection??utm_source=adblock&utm_medium=popup">
                      {translate(publisherName, "support_us")}
                    </a>
                  </div>
                  <div styleName="sign-in">
                    {translate(publisherName, "already_member")}
                    <span onClick={captureSignInClick}>{translate(publisherName, "login_1")}</span>
                  </div>
                </div>
              </>
            ) : null}
            {screen === 2 ? (
              <div styleName="content-wrapper">
                <div styleName="main-heading mb-2">{translate(publisherName, "select_your_ad_blocker")}</div>
                <div styleName="sub-heading mb-2">{translate(publisherName, "ad_blcoker_icon_appear")}</div>
                <div styleName="ab-icons">
                  <div styleName="icon-wrapper">
                    <div styleName="icon" onClick={() => captureAdBlockClick(0)}>
                      <img src="https://media.assettype.com/thequint/2023-07/e076a9ea-be4d-421a-9370-c686cfe9225a/adblock_plus.svg" />
                    </div>
                    <span>AdBlock Plus</span>
                  </div>

                  <div styleName="icon-wrapper">
                    <div styleName="icon" onClick={() => captureAdBlockClick(1)}>
                      <img src="https://media.assettype.com/thequint/2023-07/c61ab959-4761-46a4-b620-a1d3f4edbc30/ad_block.svg" />
                    </div>
                    <span>AdBlock</span>
                  </div>

                  <div styleName="icon-wrapper">
                    <div styleName="icon" onClick={() => captureAdBlockClick(2)}>
                      <img src="https://media.assettype.com/thequint/2023-07/25a8ab62-7517-445c-ba49-00e99fba188b/ublock.svg" />
                    </div>
                    <span>UBlock</span>
                  </div>

                  <div styleName="icon-wrapper">
                    <div styleName="icon" onClick={() => captureAdBlockClick(3)}>
                      <img src="https://media.assettype.com/thequint/2023-07/74073bd3-acea-471b-b731-4bb58f628ff7/ublock_origin.svg" />
                    </div>
                    <span>UBlock Origin</span>
                  </div>
                </div>
                <div styleName="back-btn" onClick={capturePrevoiousclick}>
                  {translate(publisherName, "previous")}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {showdemo && (
          <div styleName="demo-wrapper">
            <div styleName="demo-left">
              <div styleName="demo-header">{adBlockerName[selectedAdBlock]}</div>
              <div styleName="step-wrapper">
                <div styleName="num">1. </div>
                <div styleName="content">
                  {translate(publisherName, "click_on_the_icon")} <div>{renderAdBlockIcon()}</div>
                </div>
              </div>
              <div styleName="step-wrapper">
                <div styleName="num">2. </div>
                <div styleName="content">{translate(publisherName, "disbale_ad_blocker")}</div>
              </div>
              <div styleName="step-wrapper">
                <div styleName="num">3. </div>
                <div styleName="content">{translate(publisherName, "refresh_the_page")}</div>
              </div>
            </div>

            <div styleName="iframe-wrapper">
              <iframe
                width="300"
                height="150"
                src={demoGifs[selectedAdBlock]}
                allow="autoplay"
                frameBorder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

AdBlockerPopup.propTypes = {
  publisherName: string,
  isActivePlan: number,
  setPostLoginPayment: func,
};
const mapStateToProps = (state) => {
  return {
    isActivePlan: get(state, ["userReducer", "isActivePlan"]),
    publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPostLoginPayment: (value, logInFrom) => dispatch(setPostLoginPayment(value, logInFrom)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdBlockerPopup);
