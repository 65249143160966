/* eslint-disable max-len */
import get from "lodash/get";

export function isQuiz(story) {
  const hasQuizAttribute = get(story, ["metadata", "story-attributes", "Quiz", "0"], "False") === "True";

  return story.headline.search(/quiz/i) >= 0 || hasQuizAttribute;
}
// const checkArchivedSections = (story) => {
//   const archivedSections = ["gender", "law", "explainers", "my-report", "podcast", "neon", "politics", "opinion", "fit"];
//   const storyUrl = new URL(story?.url);
//   // https://thequint-malibu-beta.quintype.io/news/webqoof/edited-clip-shami-apologising-for-breaking-roza-ramzan-viral-as-real-fact-check
//   const pathSegments = storyUrl.pathname.split("/").filter((segment) => segment);
//   // ["", "news", "webqoof", "edited-clip-shami-apologising-for-breaking-roza-ramzan-viral-as-real-fact-check"] + filter out the first empty string
//   pathSegments.pop(); // ["news", "webqoof"]
//   const hasArchivedSection = pathSegments.some((segment) => archivedSections.includes(segment));

//   return hasArchivedSection;
// };
const checkArchivedSections = (story) => {
  const archivedSections = [
    "gender",
    "law",
    "explainers",
    "my-report",
    "podcast",
    "neon",
    "politics",
    "opinion",
    "fit",
  ];
  const sectionSlug = story && story.sections && story.sections[0].slug;
  const isArchived = archivedSections.includes(sectionSlug);
  console.log("isArchived", isArchived);
  return isArchived;
};
export const isPaywalled = (story) => {
  const storyAccessLevel = story["access-level-value"];
  const [freemium] = isFreemium(story);
  if (storyAccessLevel === 1) return false;
  const currentDate = new Date();
  const storyPublished = story["published-at"];
  const storyAge = Math.abs(currentDate - storyPublished) / (24 * 60 * 60 * 1000);
  if (freemium) {
    if (storyAccessLevel === 24) {
      return storyAge > 1;
    }
    if (storyAccessLevel === 48) {
      return storyAge > 2;
    }
    if (storyAccessLevel === 96) {
      return storyAge > 4;
    }
  }

  if (story.access === "subscription" && !freemium) return true;

  const sectionSlug = story && story.sections && story.sections[0].slug;
  const electionSubSection = [
    "andhra-pradesh-election",
    "arunachal-pradesh-election",
    "assam-election",
    "bihar-election",
    "chhattisgarh-election",
    "goa-election",
    "gujarat-election",
    "haryana-election",
    "himachal-pradesh-election",
    "jharkhand-election",
    "karnataka-election",
    "kerala-election",
    "madhya-pradesh-election",
    "maharashtra-election",
    "manipur-election",
    "meghalaya-election",
    "mizoram-election",
    "nagaland-election",
    "odisha-election",
    "punjab-election",
    "rajasthan-election",
    "sikkim-election",
    "tamil-nadu-election",
    "telangana-election",
    "tripura-election",
    "uttar-pradesh-election",
    "uttarakhand-election",
    "west-bengal-election",
  ];
  console.log("story", story);
  console.log("the slug is", sectionSlug);
  if (sectionSlug === "elections" || sectionSlug === "election-analysis" || electionSubSection.includes(sectionSlug)) {
    return storyAge > 2;
  }
  if (sectionSlug === "opinion") {
    return storyAge > 1;
  }

  const sectionCheck = checkArchivedSections(story["story-template"] === "explainers" ? "explainers" : story);
  if (!sectionCheck) {
    return false; // this was causing the trouble, as opinion in the slug was causing it to go behind paywall
  }

  if (story.slug.includes("fit")) {
    const archivedSubSections = ["fit-webqoof", "fit-check"];
    const regexSub = new RegExp("\\b(" + archivedSubSections.join("|") + ")\\b", "gi");
    if (regexSub.test(story.slug)) {
      return false;
    }
    const ids = [1645383, 1676411, 1641157];
    if (ids.includes(story["author-id"])) return false;
  }
  const tags = story && story.tags && story.tags.filter((it) => it.slug === "electoral-bonds");
  if (tags && tags.length && storyAge > 2) {
    return true;
  }

  return story["story-template"] === "explainers" ? storyAge > 1 : storyAge > 30;
};

export const isFreemium = (story) => {
  const { "access-level-value": storyAccessLevel } = story;

  const isFreemiumLevel = [24, 48, 96].includes(storyAccessLevel);
  return [isFreemiumLevel, storyAccessLevel];
};

export function isPodcast(story) {
  return story?.slug?.split("/")[0] === "podcast";
}
