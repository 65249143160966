/* eslint-disable max-len */
import React, { useRef, useState } from "react";
import get from "lodash/get";
import ForgotPasswordView from "../../../molecules/forgot-password";
import {
  /* forgotPassword, */ loginUser,
  signUpUser,
  sendTransactionalEmail,
  saveNewsletter,
} from "../../../helper/api";
import translate from "../../../translate/";
import { addSignUpLoginToDLNew } from "../../../helper/datalayer-utils";
import { facebookPixel, moengageTracking, getMobileNumber } from "../../../utils/utils";
import { setAccessTypeUser } from "../../../helper/access-type";
import { string, func, bool } from "prop-types";

import "./email-auth.m.css";
const EmailAuth = ({
  publisherName,
  id,
  token,
  closePopup,
  setupPlans,
  postLoginPayment,
  signIn,
  setLoading,
  setMember,
  setMakePayment,
  logInFrom,
  isChecked,
}) => {
  const [showForgotPasswordView, setShowForgotPasswordView] = useState(false);
  const [errorMsg, setError] = useState("");
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const phoneNumRef = useRef("");
  const nameInputRef = useRef("");

  function setUserOnAT(member) {
    const { email, id } = member;

    const mobileNumber = getMobileNumber(member);
    const name = member["first-name"] || member.name;

    setupPlans && setupPlans(member, "hit");
    if (global.AccessType) {
      setAccessTypeUser(email, mobileNumber, id, name)
        .then(() => {
          closePopup();
          postLoginPayment && setMakePayment(true);
        })
        .catch((e) => {
          console.error("err setting AT user:-", e);
        })
        .finally((resp) => {
          setLoading(false);
          console.log("resp:>:>:>:>:>:>", resp);
        });
    } else {
      setLoading(false);
      closePopup();
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    if (!signIn) {
      signUpUser({
        username: emailRef.current.value,
        password: passwordRef.current.value,
        email: emailRef.current.value,
        name: nameInputRef.current.value,
        "first-name": nameInputRef.current.value,
        "phone-number": Number(phoneNumRef.current.value) || 0,
        "send-welcome-email-delay": 0,
        "dont-login": false,
      })
        .then((signupResponse) => {
          if (get(signupResponse, "error", null)) {
            throw new Error(signupResponse.error.message);
          }

          if (get(signupResponse, "user", null)) {
            addSignUpLoginToDLNew("sign_up", signupResponse.user, window.location.pathname, logInFrom);
            setMember(signupResponse.user);
            setUserOnAT(signupResponse.user);
            const fbData = {
              currency: "INR",
              predicted_ltv: "1",
              value: 0,
            };
            facebookPixel("StartTrial", fbData);
            moengageTracking("registration", signupResponse.user);
            const userDetails = {
              userEmail: signupResponse.user.email,
              userName: signupResponse.user["first-name"] || signupResponse.user.name,
              type: "welcome",
              publisherName: publisherName,
            };
            sendTransactionalEmail(userDetails).then((res) => console.log("email sent", res));

            if (isChecked) {
              const url = window.location.href;
              const body = {
                email: signupResponse.user.email,
                subscriptionStatus: "Subscribed",
                referredUrl: url,
                publisher: publisherName,
              };
              saveNewsletter(body);
            }
          }
        })
        .catch((e) => {
          console.error("singnup error", e);
          if (e.status === 409) {
            setError(`The email '${emailRef.current.value}' already exists!`);
          } else {
            setError("Oops! Something went wrong");
          }
          setLoading(false);
        });
    } else {
      loginUser(emailRef.current.value, passwordRef.current.value)
        .then((currentUser) => {
          if (get(currentUser, "error", null)) {
            throw new Error(currentUser.error.message);
          }
          if (get(currentUser, "user", null)) {
            addSignUpLoginToDLNew("login", currentUser.user, window.location.pathname, logInFrom);
            const email = get(currentUser, ["user", "email"], "");
            const fbData = {
              content_name: email,
              currency: "INR",
              predicted_ltv: "1",
              value: 0,
            };
            facebookPixel("CompleteRegistration", fbData);

            setMember(currentUser.user);
            setUserOnAT(currentUser.user);
          }
        })
        .catch((ex) => {
          setError(ex.status === 401 ? "Invalid Credentials, Please try again!" : "Oops something went wrong!");
          setLoading(false);
        });
    }
  }

  function handleInvalidData(e) {
    window.alert("Mobile Should contain  10 Numbers");
  }

  function onForgotPasswordClick() {
    setShowForgotPasswordView(!showForgotPasswordView);
  }
  return (
    <>
      {showForgotPasswordView ? (
        <ForgotPasswordView toggleForgotPasswordView={onForgotPasswordClick} publisherName={publisherName} />
      ) : (
        <form styleName="grid-col-center" onSubmit={handleSubmit}>
          <div styleName="box-wrapper">
            <img
              src="https://media.assettype.com/thequint/2023-06/3d739455-b52d-48dc-ad08-9c63f91df4e3/email_login_icon.svg"
              style={{ width: "24px", height: "15px" }}
              alt="Email Login Icon"
            />
            <input
              id="userEmail"
              type="email"
              styleName="input-fields"
              ref={emailRef}
              placeholder={translate(publisherName, "email")}
              required
            />
          </div>
          {!signIn && (
            <div styleName="box-wrapper box-wrapper-person">
              <img
                src="https://media.assettype.com/thequint/2023-06/7938a4ee-341d-488f-9a97-8ab77fc45862/user_login_icon.svg"
                style={{ width: "24px", height: "25px" }}
                alt="User Login Icon"
              />
              <input
                type="text"
                ref={nameInputRef}
                styleName="input-fields"
                placeholder={translate(publisherName, "userName")}
                id="userName"
                required
              />
            </div>
          )}

          {!signIn && (
            <div styleName="box-wrapper box-wrapper-phone">
              <img
                src="https://media.assettype.com/thequint/2023-06/77cd9418-dc5c-47f3-8031-cf4f88bf7c04/phone_login_icon.svg"
                style={{ width: "45px", height: "45px" }}
                alt="Phone Login Icon"
              />
              <input
                onInvalid={handleInvalidData}
                type="number"
                pattern="[\d]{10}"
                ref={phoneNumRef}
                styleName="input-fields"
                placeholder={translate(publisherName, "number")}
                id="userMobileNumber"
              />
            </div>
          )}
          <div styleName="box-wrapper box-wrapper-password">
            <img
              src="https://media.assettype.com/thequint/2023-06/42e64d40-6c4b-4c88-816d-0e79c3957c7d/lock_login_icon.svg"
              style={{ width: "17px", height: "25px" }}
              alt="Lock Login Icon"
            />
            <input
              type="password"
              styleName="input-fields"
              ref={passwordRef}
              placeholder={translate(publisherName, "password")}
              required
            />
          </div>

          {errorMsg && <p styleName="error">{errorMsg}</p>}
          <div styleName={signIn ? "signup-button-wrapper" : ""}>
            {signIn && (
              <b styleName="forgot-pwd" onClick={onForgotPasswordClick}>
                {translate(publisherName, "forgot_password")}
              </b>
            )}
            <div styleName={`${signIn ? "box-wrapper-signUp" : ""}`}>
              <input
                id={signIn ? "loginSubmit" : "submitButton"}
                type="submit"
                styleName="signup-action-btn"
                value={signIn ? translate(publisherName, "login_1") : translate(publisherName, "sign_up_1")}
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
};

EmailAuth.propTypes = {
  setMember: func,
  setLoading: func,
  closePopup: func,
  setMakePayment: func,
  postLoginPayment: bool,
  setupPlans: func,
  publisherName: string,
  id: string,
  token: string,
  signIn: bool,
  logInFrom: string,
  isChecked: bool,
};
export default EmailAuth;
