import React from "react";

import { ResponsiveImage } from "@quintype/components";

const Youtube = () => (
  <ResponsiveImage
    slug="thequint/2024-07/45293357-feaa-4921-9661-374b41561219/youtube.png"
    type="image/webp"
    alt="Youtube"
    aspectRatio={[1, 1]}
    defaultWidth={28}
    eager={false}
    imgParams={{ auto: ["format", "compress"] }}
    imageCDN="media.assettype.com"
  />
);

export default Youtube;
