/* eslint-disable max-len */
export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  BIG_STORY_SECTION_PAGE: "big-story-section-page",
  TAG_PAGE: "tag-page",
  AUTHOR_PAGE: "author-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  CATALOG_PAGE: "catalog-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  STATIC_PAGE: "static-page",
  PLAN_SELECTION_PAGE: "plan-selection-page",
  CAMPAIGN_PAGE_PAGE: "campaign-page",
  HINDI_CAMPAIGN_PAGE: "hindi-campaign-page",
  MEMBERSHIP_PAGE: "membership-page",
  INDEPENDENCE_DAY_MEMBERSHIP: "independence-day-membership",
  DASHBOARD_PAGE: "dashboard-page",
  BOOKMARK_PAGE: "bookmark-page",
  UGC_FORM_PAGE: "ugc-form-page",
  SUBSCRIBE_PAGE: "Subscribe-Page",
  VISUAL_STORY: "visual-story",
  RESET_PASSWORD_PAGE: "reset-password-page",
  PAYMENT_PAGE_TEMP: "payment-page-temp",
  PAYMENT_PAGE: "payment-page",
  MEMBER_PAYMENT_SUCCES: "member-payment-success",
  MEMBER_PAYMENT_FAILURE: "member-payment-failed",
  MEMBER_PAYMENT_FAILURE_RECURRING: "member-payment-failed-recurring",
  CAMPAIGN_PAYMENT_SUCCES: "campaign-payment-success",
  CAMPAIGN_PAYMENT_FAILURE: "campaign-payment-failed",
  NOT_AVAILABLE_PAGE: "not-available-page",
});

export const TAG_PAGE_URL_PREFIX = "/topic/";

export const LOGOS = {
  thequint_logo: "https://media.assettype.com/thequint/2020-05/396d150f-b9e4-42a5-b9e1-6a701adb90d6/TQLogo_120x120.png",
  hindi_logo: "https://media.assettype.com/quint-hindi/2020-07/5e1b55c9-87e1-4097-813c-88b34f292e90/Group__1_2x.png",
  fit_logo: "https://media.assettype.com/thequint-fit/2020-07/6bfd09f0-f7b9-42bc-ab9b-45229bc41819/FitBigLogo_1_2x.png",
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const CUSTOM_PUBLISH_DATES = [
  { customSlug: "a-infrastructure-ltd-roofing-construction", customDate: "10 Oct 2021, 6:21 PM IST" },
  { customSlug: "Raksha Health Insurance Driven By Technology", customDate: "1 Oct 2021, 6:21 PM IST" },
  { customSlug: "stellar-models-of-entrepreneurship-and-innovation-at-upes", customDate: "1 Oct 2021, 2:00 PM IST" },
  { customSlug: "Businesses Look Out To Securisk For Insurance Strategies", customDate: "4 Oct 2021, 6:28 PM IST" },
  { customSlug: "Optima Insures Everyone Can Survive Risky Times", customDate: "10 Oct 2021, 6:28 PM IST" },
  { customSlug: "GB Professional Associates HR Law Compliance Experts", customDate: "4 Oct 2021, 6:10 PM IST" },
  {
    customSlug: "Sentinel Security Smart Solutions Favoured By Industries and Businesses ",
    customDate: "15 Oct 2021, 7:06 PM IST",
  },
  { customSlug: "Maple-Staffing-Solutions-Manpower-Outsourcing", customDate: "15 Oct 2021, 6:47 PM IST" },
];

export const INSIDER_BENEFITS_ENGLISH = [
  {
    text: "Access to all paywalled content on site ",
    hindiText: "पेवॉल कंटेंट का एक्सेस",
    description: "Priority pass to unlimited stories, op-eds, videos, podcasts, and more across The Quint ",
    hindiDescription: "क्विंट हिंदी पर खबरें, ओपिनियन, वीडियो, पॉडकास्ट और बहुत कुछ का एक्सेस",
    premium: false,
  },
  {
    text: "Ad-free experience on The Quint",
    hindiText: "क्विंट हिंदी पर बिना ऐड के कंटेंट",
    description: "Enjoy seamless & insightful content without ad interruptions across all devices",
    hindiDescription: "सभी डिवाइस पर क्विंट हिंदी के कंटेट का बिना ऐड का मजा लें",
    premium: false,
  },
  {
    text: "Early previews of our Special Projects ",
    hindiText: "स्पेशल प्रोजेक्ट का प्रीव्यू",
    description:
      "Get exclusive previews to all documentaries, investigations, and special features, before online release",
    hindiDescription: "हमारी डॉक्यूमेंट्री, इन्वेस्टीगेशन और स्पेशल फीचर्स को ऑनलाइन रिलीज से पहले देखिए",
    premium: false,
  },
  {
    text: "Report for us as a Citizen Journalist ",
    hindiText: "सिटिजन जर्नलिस्ट बनकर हमारे लिए रिपोर्ट करें",
    description: "Work with our MyReport team to highlight issues and stories that matter to you",
    hindiDescription: "हमारी Myरिपोर्ट टीम के साथ काम करें और उन मुद्दों को उठाएं, जो आपके लिए अहम हैं",
    premium: false,
  },
  {
    text: "Get fake news verified by Team WebQoof ",
    hindiText: "टीम वेबकूफ से फेक न्यूज वेरिफाई करवाएं",
    description: "Want to get a suspicious story checked out? WhatsApp it to Team WebQoof, and we’ll get it done ",
    hindiDescription: "किसी खबर पर संदेह है? हमें WhatsApp करें, और हम आपके लिए उसे वेरिफाई करेंगे",
    premium: false,
  },
  {
    text: "Credit mention in our Special Projects",
    hindiText: "स्पेशल प्रोजेक्ट में मिलेगा क्रेडिट",
    description: "As a Premium Member, your name will be featured on the credit roll of our Special Projects",
    hindiDescription: "प्रीमियम मेंबर के तौर पर, आपका नाम हमारे स्पेशल प्रोजेक्ट के क्रेडिट रोल में शामिल किया जाएगा",
    premium: true,
  },
  {
    text: "Exclusive goodie bag ",
    hindiText: "क्विंट हिंदी का एक्सक्लूसिव गुडीज बैग",
    description: "Limited-edition merchandise from The Quint curated by our in-house artists for all Premium Members ",
    hindiDescription: "सभी प्रीमियम मेंबर्स के लिए हमारे आर्टिस्ट्स द्वारा तैयार किया गया गुडीज बैग",
    premium: true,
  },
  {
    text: "Write for our Members' Opinion column",
    hindiText: "मेंबर्स ओपिनियन कॉलम के लिए लिखें",
    description: "Share your thoughts with the world through an exclusive column for all Premium Members",
    hindiDescription: "प्रीमियम मेंबर बनकर अपने विचार पूरी दुनिया के साथ शेयर कीजिए",
    premium: true,
  },
  {
    text: "Private members’ group ",
    hindiText: "प्राइवेट मेंबर्स ग्रुप",
    description:
      "An exclusive group where you can interact with our reporters, discover new stories, and share your feedback ",
    hindiDescription:
      "एक एक्सक्लूसिव ग्रुप, जहां आप हमारे रिपोर्टर के साथ बात कर सकते हैं, नए आइडिया खोज सकते हैं, और अपना फीडबैक दे सकते हैं",
    premium: false,
  },
  {
    text: "Exclusive member events",
    hindiText: "एक्सक्लूसिव मेंबर्स इवेंट",
    description:
      "Invites to Q-Screenings, editorial, brainstorms, expert sessions, and other online and offline events ",
    hindiDescription: "Q-स्क्रीनिंग, एडिटोरियल, एक्सपर्ट सेशन और दूसरे ऑनलाइन और ऑफलाइन इवेंट के इवनाइट",
    premium: false,
  },
  {
    text: "Curated members-only newsletters",
    hindiText: "मेंबर्स के लिए खास न्यूजलेटर",
    description:
      "Behind-the-story insights, upcoming campaigns, event highlights, and news roundups, curated just for you   ",
    hindiDescription: "खबरों के पीछे की कहानी, नए कैंपेन, इवेंट की खास बातें और बहुत कुछ, सिर्फ आपके लिए",
    premium: false,
  },
];

export const INSIDER_BENEFITS_HINDI = [
  {
    description:
      "हमारा मानना है कि खबरें फ्री होनी चाहिए, लेकिन ईमानदार पत्रकारिता पर पैसे खर्च होते हैं. आपका सपोर्ट हमें स्वतंत्र रखता है.",
  },
  {
    title: "मेंबर बनें",
  },
  {
    benefits: [
      {
        text: "एक्सक्लूसिव हैंपर",
        description:
          "हमारे प्रीमियम मेंबर के रूप में, आपको क्विंट की तरफ से लिमिटेड एडिशन वाला एक एक्सक्लूसिव मेंबर हैंपर मिलेगा, जिसे हमारे इन-हाउस आर्टिस्ट द्वारा तैयार किया गया है. आप जरूरी स्टोरीज बताने में हमारी मदद करते हैं, इसलिए हम आपका शुक्रिया अदा करते हैं.",
        image: "https://media.assettype.com/thequint/2021-10/98206960-0587-4f34-afba-a2ebb47eba2d/22_layers.svg",
      },
      {
        text: "मेंबर्स का ओपिनियन कॉलम",
        description:
          "हमारे मेंबर्स ओपिनियन कॉलम के लिए अपनी स्टोरी भेजकर दुनिया के साथ अपने विचार शेयर करें. हमारी एडिटोरियल टीम से स्टोरी के अप्रूव होने के बाद, इसे लोगों तक पहुंचाने के लिए हमारी साइट और सोशल मीडिया पर प्रमोट किया जाता है.",
        image: "https://media.assettype.com/thequint/2021-10/3f74b43c-2bec-4b9c-b62a-25d04cea1b77/7_layers.svg",
      },
      {
        text: "एक्सक्लूसिव डॉक्यूमेंट्री स्क्रीनिंग",
        description:
          "आपको Q स्क्रीनिंग के लिए इनवाइट मिलेगा. ये इवेंट केवल मेंबर्स के लिए है, जहां हम अपने स्पेशल प्रोजेक्ट ऑनलाइन रिलीज करने से पहले प्रीमियर करते हैं. इसके बाद आपको रिपोर्टर्स के साथ चर्चा करने का भी मौका मिलेगा.",
        image: "https://media.assettype.com/thequint/2021-10/bea0e422-cf85-43c4-98ee-48b47e18ade9/7_layers__1_.svg",
      },
      {
        text: "एडिटोरियल मीटिंग",
        description:
          "हमारे मैनेजिंग एडिटर, सीईओ और दूसरे सीनियर एडिटर्स के साथ एक सेशन, जहां आप हमें अपना फीडबैक और आने वाले एडिटोरियल प्रोजेक्ट पर आइडिया दे सकते हैं. इस सेशन से केवल इनवाइट के जरिये जुड़ा जा सकता है.",
        image: "https://media.assettype.com/thequint/2021-10/9062671d-4189-4fd3-a910-e2a2483657a8/8_layers.svg",
      },
      {
        text: "स्पेशल प्रोजक्ट्स तक जल्दी पहुंच",
        description:
          "मेंबर के रूप में, आप हमारी डॉक्यूमेंट्रीज, इंवेस्टिगेशन और स्पेशल प्रोजेक्ट्स दूसरों से पहले देख सकते हैं. क्विंट की वेबसाइट और सोशल प्लेटफॉर्म्स पर इन प्रोजेक्ट्स की रिलीज से पहले ही ये आपके इनबॉक्स में पहुंच जाते हैं.",
        image: "https://media.assettype.com/thequint/2021-10/e613b75e-fa1c-4e46-93a2-c65611f56459/13_layers.svg",
      },
      {
        text: "ऐड-फ्री एक्सपीरियंस",
        description: "आपके और न्यूज के बीच यहां कुछ भी नहीं होगा... ऐड भी नहीं.",
        image: "https://media.assettype.com/thequint/2021-10/17afeff9-6a06-4341-861c-36a16b346ac2/8_layers__1_.svg",
      },
      {
        text: "प्राइवेट मेंबर्स का ग्रुप",
        description:
          "आप फेसबुक के एक प्राइवेट ग्रुप का भी हिस्सा बनेंगे, जहां आप क्विंट के जर्नलिस्ट से बात कर सकते हैं, स्टोरी के पीछे के किस्सों को जान सकते हैं और खास मेंबर्स के लिए होने वाले हमारे इवेंट के लिए साइन-अप भी कर सकते हैं. इसके साथ ही आप अपना फीडबैक सीधा हमारे रिपोर्टर्स को दे सकते हैं.",
        image: "https://media.assettype.com/thequint/2021-10/b51e1f49-4fe4-44bd-8feb-941d27ad91b8/7_layers__2_.svg",
      },
      {
        text: "एक्सक्लूसिव न्यूजलेटर",
        description:
          "ऐसा न्यूजलेटर जिसमें आपको स्टोरी के पीछे के किस्से, हमारे एडिटोरियल कैंपेन, न्यूज और इवेंट हाईलाइट्स मिलेंगे.",
        image: "https://media.assettype.com/thequint/2021-10/223a5744-6bcb-4f4b-b930-ee6f1b164ba3/8_layers__2_.svg",
      },
    ],
  },
];

export const QUINT_CAMPAIGNS = [
  {
    Keywords:
      "The Quint Campaign, The Quint's Special Projects, The Quint Special Projects, The Quint Special Features, The Quint documentaries, The Quint Fact Checks, The Quint Campaign Page",
    Page_Title: "The Quint's Special Projects",
    Page_Description_1:
      "The Quint's special features, documentaries, investigations, and fact checks make us stand apart in the clutter of mainstream media. But bringing out these stories take up time, money, manpower, and often, risks borne our on-ground reporters. And that's where you, our Quint Member, come in.",
    Page_Description_2:
      "By helping us meet our production targets, it is YOU who are ensuring that these stories see the light of day.",
  },
  {
    benefites: [
      "Access to all paywalled content on site",
      "Ad-free experience on The Quint",
      "Early previews of our Special Projects",
      "Report for us as a Citizen Journalist",
      "Private members’ group",
      "Exclusive member events",
      "Curated members-only newsletters",
      "Get fake news verified by Team WebQoof",
      "Credit mention in our Special Projects",
      "Exclusive goodie bag",
      "Write for our Members' Opinion column",
    ],
  },
  {
    description: "Your Contribution Matters",
  },
  {
    url: "https://thequint.com/image",
  },
  {
    title: "QUINT MEMBER BENEFITS",
  },
];

export const HINDI_QUINT_CAMPAIGNS = [
  {
    Keywords:
      "The Quint Campaign, The Quint's Special Projects, The Quint Special Projects, The Quint Special Features, The Quint documentaries, The Quint Fact Checks, The Quint Campaign Page",
    Page_Title: "क्विंट स्पेशल प्रोजेक्ट्स",
    Page_Description_1:
      "क्विंट के स्पेशल फीचर्स, डॉक्यूमेंट्री, इंवेस्टीगेशन और फैक्ट चेक हमें मेनस्ट्रीम मीडिया से अलग करते हैं. लेकिन इन स्टोरीज को आप तक लाने में काफी वक्त, पैसा और मेहनत के साथ-साथ हमारे रिपोर्टर्स के लिए रिस्क भी होता है. और यहीं पर आप, हमारे क्विंट मेंबर की हमें जरूरत होती है.",
    Page_Description_2:
      "प्रोडक्शन टारगेट को पूरा करने में हमारी मदद करके आप इन स्टोरीज को लोगों तक पहुंचाने में मदद करते हैं.",
  },
  {
    benefites: [
      "पेवॉल कंटेंट का एक्सेस",
      "क्विंट हिंदी पर बिना ऐड के कंटेंट",
      "स्पेशल प्रोजेक्ट का प्रीव्यू",
      "सिटिजन जर्नलिस्ट बनकर हमारे लिए रिपोर्ट करें",
      "प्राइवेट मेंबर्स ग्रुप",
      "एक्सक्लूसिव मेंबर्स इवेंट",
      "मेंबर्स के लिए खास न्यूजलेटर",
      "टीम वेबकूफ से फेक न्यूज वेरिफाई करवाएं",
      "स्पेशल प्रोजेक्ट में मिलेगा क्रेडिट",
      "एक्सक्लूसिव हैंपर",
      "मेंबर्स ओपिनियन कॉलम के लिए",
    ],
  },
  {
    description: "Your Contribution Matters",
  },
  {
    url: "https://thequint.com/image",
  },
  {
    title: "मेंबरशिप के फायदे",
  },
];

// Data not available
export const QUINT_INSIDER_CARDS = [];

export const QUINT_PROJECT_CARDS = [
  {
    updatedAt: "Thu Dec 23 2021 12:58:36 GMT+0530 (India Standard Time)",
    Card_Image: "thequint/2021-09/56629fab-6f51-4ef1-85e4-e3e83bedb1eb/Group_35721.png",
    coverage: "https://www.thequint.com/collection/jihad-in-love",
    Card_Headline: "Jihad in Love",
    Card_Unique_Id: "706",
    createdAt: "Wed Jun 30 2021 12:34:56 GMT+0530 (India Standard Time)",
    Card_Description:
      "From the interiors of Uttar Pradesh and Haryana to the heart of New Delhi, The Quint has been reporting on the reality of 'love jihad' claims and the politics behind it, through investigative ground reports, documentaries and legal explainers. As states bring in laws against 'Love Jihad', help us break down the allegations and bring you the truth by supporting our coverage.",
    breakup: [
      {
        Manpower: "77362",
      },
      {
        "Post Production": "255688",
      },
      {
        "Travel Cost": "28113",
      },
    ],
    timestamp: 1640244516156,
    display: {
      current: "yes",
      previous: "no",
      default: "no",
    },
  },
  {
    Card_Description:
      "In 2016, The Quint did a ground report from Haryana’s Mewat district, to examine the impact of the Cow Protection law on the ground. We discovered that it did little to reduce cow slaughter and smuggling. In 2021, we revisited Mewat to find that cow smuggling and cow slaughter continues. We also found that cow vigilantism has evolved into a full-fledged mobocracy, where extortion, entrapment and hate crime is the norm. While the law is stringent, most cow slaughter and smuggling cases do not stand in the court due to lack of evidence leading to conviction rates as low as 2%. This docu does a deep dive into the contentious law and its fallout on Mewat's residents.",
    display: {
      default: "no",
      current: "no",
      previous: "yes",
    },
    createdAt: "Mon Nov 29 2021 11:42:18 GMT+0530 (India Standard Time)",
    breakup: [
      {
        Travel: "26500",
      },
      {
        "Manpower ": "151887",
      },
      {
        "Post-production": "135807",
      },
    ],
    Card_Unique_Id: "795",
    coverage: "https://www.thequint.com/special-projects",
    updatedAt: "Wed Dec 22 2021 11:38:54 GMT+0530 (India Standard Time)",
    Card_Image: "thequint/2021-11/0d19719e-5d1a-4680-879e-e3fbcf60a7a7/sp_hero2.jpg",
    Card_Headline: "Gau Raksha: The Real Picture",
    timestamp: 1640153334487,
  },
];

export const TESTIMONIALS_USER_INFO = [
  {
    imgUrl: "thequint/2022-04/78165808-fddf-4bc2-98c4-5d5f502a1282/user_two.webp",
    name: "Waqar Jamil",
    content:
      "The Quint is doing its job very well and is delivering on its responsibility of honest and critical journalism. The Quint's way of putting out facts in the form of short editorial videos on YouTube where the viewers are presented with questions and asked to think, is very good. More power to the entire Quint team. I will keep on supporting you guys to the best of my capabilities. Keep on doing the good work.",
  },
  {
    imgUrl: "thequint/2022-04/7e9887f1-17d0-4fbf-9d99-1780d75ad0db/user_three.webp",
    name: "Vinay Biradar",
    content: "A Good Platform to expand and improve. Even small activities are covered and voices are listened to.",
  },
  {
    imgUrl: "thequint/2022-04/c18380ef-d27f-44d5-93fb-df0d8d5d241f/user_four.webp",
    name: "Amin Shroff",
    content:
      "You are doing a tremendous service for the country. Please do continue to highlight topics of national importance. It will go a long way to let more people become aware of the importance of having a secular country.",
  },
  {
    imgUrl: "thequint/2022-04/4abb61bf-1423-4807-95f5-7ed636625c3b/user_five.webp",
    name: "Arisha",
    content:
      "I really enjoy watching the content on The Quint. I also read articles. In today's times, the way media is functioning is quite horrifying. The Quint is like a silver lining in a cloudy sky.",
  },
  {
    imgUrl: "thequint/2022-04/13c5cb8b-55b2-43ae-950f-1cf9a8f555ec/user_six.webp",
    name: "Prabal Malaker",
    content:
      "The Quint provides incisive analyses of activities not usually covered by mainstream media. Its opinions are independent. This is the reason I subscribe to it.",
  },
  {
    imgUrl: "thequint/2022-04/31a1b717-ad62-43d8-b22a-e67e1bbffb58/user_seven.webp",
    name: "Leela Sachdev",
    content:
      "The Quint has been among the best online media groups and my favourite to get the latest news and a trusted version of what's happening in our world. Excellent articles, well-researched facts and breaking news, make The Quint a great place to stay informed. Love following the investigation, community, social news, sports, and health bytes. Your special project and ground reports deserve kudos too. Keep up the good work!",
  },
  {
    imgUrl: "thequint/2022-04/26016079-cd6c-4466-b812-8544b9e9293f/user_eight.webp",
    name: "Swati Vaidya",
    content:
      "Hi everyone at The Quint, greetings and thank you all for your credible journalism. I am impressed by the special documentaries made on Sundarban villagers, COVID orphans, and recently screened Ba- ijjat Bari. Wishing you all the best of skills, chances and health in your endeavours. - Swati Vaidya",
  },
  {
    imgUrl: "thequint/2022-04/8e277634-27a6-4a9e-a93f-5e551f14d3f5/user_nine.webp",
    name: "Maninder Bir",
    content:
      "You guys are rocking. As of now I have seen only one documentary, 'Baizzat Bari'. It was awesome, it needs courage to highlight these topics.Thank you. Keep moving, keep rocking. Hoping for more transparency and valued journalism.",
  },
  {
    imgUrl: "thequint/2022-04/2c777af5-9b23-449b-863a-6975f48479f2/user_ten.webp",
    name: "Eric Pinto",
    content:
      "The Quint has been one of the most outspoken of News portals, especially in news that matter but not headlined in mainstream media. Some of The Quint exposes worth mentioning are the mismatch in the numbers of votes cast and validated in the last general elections as well as in some other Indian states. Another is the documentation of anti minority action by majoritarian communities which are not brought out by mainstream media.",
  },
  {
    imgUrl: "thequint/2022-04/6528401b-b56e-42a0-a75f-fd7bc141ffc4/user_eleven.webp",
    name: "Ravi M",
    content:
      "By now it is quite obvious too all that barring a couple, media houses have started relying on government advertisements. Whoever keeps the platform profitable will be served, in this case the advertisers. All such channels are colloquially referred to as GODI MEDIA these days. I stopped watching them 5 years back and leaned toward Internet news portals and came across The Quint. Since then, I have been a regular viewer and reader of Quint, I love 'Janab Aise Kaise', 'I Have a Question' and investigative reporting by Poonam, the on-the-ground reports by Asmita, editorials of Raghav ji and 'Yeh Jo India Hai Na' by Rohit ji. I frequently check the Webqoof before I do my own fact check to save time, I visit the section almost 2-3 times a week. The citizen journalist section is definitely an innovative one as it democratises the news platform for many who want to tell their story but lack the means. The makings of 'Lynchistan', 'Mission Hate', 'Baizzat Bari', the piece on electoral bonds by Poonam are all top class new stories that show the quality of work done by team Quint.",
  },
  {
    imgUrl: "thequint/2022-04/803f611c-aace-488b-b367-65a2f0f9f626/user_twelve.webp",
    name: "Vikram Kulasekara",
    content:
      "In these testing times full of assumptions and false information, mainstream media is often dictated by the ruling party. General public are fed with manipulated information with a deranged agenda in mind. The need of the hour is facts and truth. The Quint is a unique organisation which not only has a vision but stands by it. The initiatives, effort, and dedication are noteworthy. The Quint is a pioneer in India when it comes to fact-checking. Above all, their ground work and field study are outstanding. I have been following The Quint closely for quite some time now & their consistency makes me more committed and responsible. I stand with The Quint on this challenging journey and I strongly believe that the best is yet to come.",
  },
  {
    imgUrl: "thequint/2022-04/0e63e81c-0f14-4f0f-9fd4-ce4315e531dc/user_thirteen.webp",
    name: "Dr. Dinakar M V",
    content:
      "The Quint is a digital media platform that is covering the stories of people totally ignored by mainstream media. The poor, downtrodden, voiceless are not their consumers, and hence, are not visible to big media houses! It is the responsibility of people like us to support organisations like The Quint, in whichever way possible. Let me take this opportunity to suggest the editorial board to include more stories from southern states, of government employees, of pensioners etc.",
  },
];

export const MEMBERSHIP_FAQ_METADATA = {
  title: "Membership Programme FAQ: Frequently Asked Questions for The Quint Membership Programme",
  description:
    "Membership Programme FAQ (Frequently Asked Questions) helps you understand about The Quint members and its benefits such as The Quint’s premium ad-free subscription, invites to special screenings, brainstorms with our senior editors, exclusive webinars, curated newsletters, and much more",
  Keywords:
    "Membership Programme, Membership Programme FAQ, Frequently Asked Questions for The Quint Membership Programme, ad-free subscription",
};

export const HQ_SPECIAL_PROJECT_METADATA = {
  title:
    "क्विंट हिंदी स्पेशल प्रोजेक्ट्स | क्विंट हिंदी कैंपेन | The Quint Hindi Special Projects | Exclusive Stories in Hindi",
  description:
    "क्विंट स्पेशल प्रोजेक्ट्स या द क्विंट कैंपेन पर आपको मिलेंगे स्पेशल फीचर्स, डॉक्यूमेंटरीज, इंवेस्टीगेशन और फैक्ट चेक जो हमें मेनस्ट्रीम मीडिया से अलग करते हैं. प्रोडक्शन टारगेट को पूरा करने में हमारी मदद करके आप इन स्टोरिज को लोगों तक पहुंचाने में मदद करते हैं.",
  Keywords:
    "द क्विंट कैंपेन, क्विंट स्पेशल प्रोजेक्ट्स, स्पेशल प्रोजेक्ट्स, क्विंट डॉक्यूमेंटरीज, द क्विंट कैंपेन पेज, The Quint Hindi Special Projects, Exclusive Stories in Hindi",
};

export const EN_SUBSCRIBE_PAGE_METADATA = {
  title: "The Quint Newsletter: Best Newsletters to Subscribe, Daily Newsletters, News Subscription Online | The Quint",
  description:
    "The Quint Newsletter: Subscribe the The Quint daily newsletter and stay updated with the latest news, insightful commentary and in-depth analysis, delivered to your inbox.",
  Keywords:
    "The Quint, Quint Newsletter, The Quint Newsletter, The Quint News, The Quint Daily News, The Quint Daily Newsletter, Quint Newsletter Today, Newsletter Subscribe, Daily Newsletter Subscription, Best Newsletters, Best Newsletters to Subscribe",
};

export const HI_SUBSCRIBE_PAGE_METADATA = {
  title:
    "Quint Hindi Newsletter: सब्सक्राइब डेली न्यूजलेटर, Hindi News Subscription Online, Best Newsletters in Hindi | Quint Hindi",
  description:
    "Quint Hindi Newsletter: द क्विंट डेली न्यूजलेटर को सब्सक्राइब करें और अपने इनबॉक्स में डिलीवर की जाने वाली लेटेस्ट न्यूज, इनसाइट कमेंट्री और गहन विश्लेषण से अपडेट रहें।",
  Keywords:
    "The Quint, Quint Newsletter, Quint Hindi Newsletter, Quint Hindi News, Quint Hindi Daily Newsletter, Quint Hindi Newsletter Today, Newsletter Subscribe, Daily Newsletter Subscription, Best Newsletters, Best Hindi Newsletters, Best Newsletters to Subscribe, न्यूजलेटर, सब्सक्राइब न्यूजलेटर",
};

export const EN_MEMBER_ONLY_METADATA = {
  title: "Quint's Members Only: Premium Articles, Exclusive News Stories and Analysis | The Quint",
  description:
    "Quint's Members Only: Read The Quint's Premium Articles, Exclusive News Stories, Paid News Articles, Analysis, Explainers and explore top stories videos, photos and more on Latest News, Breaking News LIVE, Top News Headlines, Viral Videos News Updates - The Quint.",
  Keywords:
    "Quint, The Quint, Quint Premium Articles, Premium News, Exclusive News Today, Exclusive Stories, Premium Content, Paid News Articles",
};

export const HI_MEMBER_ONLY_METADATA = {
  title: "Quint's Members Only: Premium and Exclusive News Articles in Hindi",
  description:
    "Quint's Members Only: Best premium and exclusive news articles in Hindi.हिंदी क्विंट के प्रीमियम आर्टिकल्स, एक्सक्लूसिव न्यूज स्टोरीज, पेड न्यूज आर्टिकल्स, एनालिसिस, एक्सप्लेनर्स पढ़ें Hindi Quint पर",
  Keywords:
    "Quint, The Quint, Quint Hindi, Quint Premium Articles, Premium News, Exclusive News Today, Exclusive Stories, Premium Content, Paid News Articles",
};

export const sectionOrPages = ["hindi-campaign-page", "Subscribe-Page", "membership-faq-page", "members-only"];
