/* eslint-disable object-shorthand */
import React, { useEffect } from "react";
import { bool, string } from "prop-types";
// import { createDfpAdComponent } from "@quintype/components";
// import get from "lodash/get";
// import { connect } from "react-redux";

import { getAdSlots } from "./dfp-ad-utils";
const ViewPortSizeMappingBanner300x600 = [
  { viewport: [300, 0], sizes: [[300, 600]] },
  { viewport: [160, 0], sizes: [[160, 600]] },
  { viewport: [120, 0], sizes: [[120, 600]] },
];

const ViewPortSizeMappingBanner970x90and728x90 = [
  { viewport: [970, 0], sizes: [[970, 90]] },
  { viewport: [728, 0], sizes: [[728, 90]] },
];

const ViewPortSizeMappingBanner970x250 = [{ viewport: [970, 0], sizes: [[970, 250]] }];

const ViewPortSizeMappingBanner300x250 = [
  { viewport: [300, 0], sizes: [[300, 250]] },
  { viewport: [336, 0], sizes: [[336, 280]] },
  { viewport: [250, 0], sizes: [[250, 250]] },
  { viewport: [200, 0], sizes: [[200, 200]] },
];

const get970x90ResponsiveAd = (id) => ({
  adUnit: id,
  sizes: [
    [970, 90],
    [970, 250],
  ],
  viewPortSizeMapping: ViewPortSizeMappingBanner970x90and728x90,
});

const get970x250ResponsiveAd = (id) => ({
  adUnit: id,
  sizes: [[970, 250]],
  viewPortSizeMapping: ViewPortSizeMappingBanner970x250,
});
const getStandard300x250Ad = (id) => ({
  adUnit: id,
  sizes: [
    [300, 250],
    [336, 280],
    [250, 250],
    [200, 200],
  ],
  viewPortSizeMapping: ViewPortSizeMappingBanner300x250,
});

const get300x600Ad = (id) => ({
  adUnit: id,
  sizes: [
    [120, 600],
    [160, 600],
    [300, 600],
  ],
  viewPortSizeMapping: ViewPortSizeMappingBanner300x600,
});

const TqGet300x600Ad = (id) => ({
  adUnit: id,
  sizes: [[300, 600]],
});

const TqGetStandard300x250Ad = (id) => ({
  adUnit: id,
  sizes: [[300, 250]],
});

const TqgetStandard300x600Ad = (id) => ({
  adUnit: id,
  sizes: [[300, 600]],
});

const getStandard300x600 = (id) => ({
  adUnit: id,
  sizes: [[300, 600]],
});
const getStandard728x90Ad = (id) => ({
  adUnit: id,
  sizes: [[728, 90]],
});

const getTopMobileAd = (id) => ({
  adUnit: id,
  sizes: [[320, 100]],
});

const get320x50stickyAd = (id) => ({
  adUnit: id,
  sizes: [[320, 50]],
});

const getHomeTopMobileAd = (id) => ({
  adUnit: id,
  sizes: [[320, 100]],
});

const get1x1Ad = (id) => ({
  adUnit: id,
  sizes: [[1, 1]],
});

const TQ_CONFIG = {
  tq_desktop_home_topAd: get970x90ResponsiveAd("TQ_970x250_Top"),
  tq_desktop_home_rightATF: TqGetStandard300x250Ad("TQ_Desktop_HP_RightATF_300x250"),
  tq_desktop_home_hdfc: TqGetStandard300x250Ad("TQ22_Desktop_HP_RightMID_300x250"),
  tq_desktop_home_home300x600: TqGet300x600Ad("TQ_Desktop_HP_RightMID_300x600"),
  tq_desktop_home_rightATF2: TqGetStandard300x250Ad("TQ_Desktop_HP_BTF_300x250"),
  tq_desktop_section_topAd: get970x90ResponsiveAd("TQ_970x250_Top"),
  tq_desktop_section_home300x600: TqGet300x600Ad("TQ_Desktop_HP_RightMID_300x600"),
  tq_desktop_section_campaignTopAd: get970x250ResponsiveAd("TQ_Desktop_Climate_SP_970x250"),
  tq_desktop_section_rightATF: TqGetStandard300x250Ad("TQ_Desktop_SP_RightATF_300x250"),
  tq_desktop_section_campaignRightATF: getStandard300x600("TQ_Desktop_Climate_SP_300x600"),
  tq_desktop_section_rightATF2: TqGetStandard300x250Ad("TQ_Desktop_SP_RightMID_300x250"),
  tq_desktop_article_storyTopAd: get970x90ResponsiveAd("TQ_970x250_Top"),
  tq_desktop_article_campaignStoryTopAd: get970x250ResponsiveAd("TQ_Desktop_Climate_AP_970x250"),
  tq_desktop_article_rightATF: TqGetStandard300x250Ad("TQ_Desktop_AP_RightATF_300x250"),
  tq_desktop_article_campaignRightATF: TqgetStandard300x600Ad("TQ_Desktop_Climate_AP_300x600"),
  tq_desktop_article_rightstory2: TqGetStandard300x250Ad("TQ_Desktop_AP_MID_300x250"),
  tq_desktop_article_campaignRightstory2: TqgetStandard300x600Ad("TQ_Desktop_Climate_AP_300x600"),
  tq_desktop_article_content1: getStandard728x90Ad("TQ_Desktop_AP_BTF_728x90"),
  tq_desktop_article_content2: getStandard728x90Ad("TQ_Desktop_AP_BTF2_728x90"),
  tq_desktop_article_photos: getStandard728x90Ad("TQ_Desktop_Photo_Story_Ad"),
  tq_mobile_article_storyTopAd_mobile: TqGetStandard300x250Ad("TQ_Mobile_AP_Top_300x250"),
  tq_mobile_article_mid1: TqGetStandard300x250Ad("TQ_Mobile_AP_MID_300x250"),
  tq_mobile_article_mid2: TqGetStandard300x250Ad("TQ_Mobile_AP_BTF_300x250"),
  tq_mobile_article_photos: getStandard300x250Ad("TQ_Mobile_Photo_Story_Ad"),
  tq_mobile_article_stickey: get320x50stickyAd("TQ_Mobile_Sticky_320x50"),
  tq_mobile_home_topAd: getTopMobileAd("TQ_Mobile_HP_Top_320x100"),
  tq_mobile_home_mid1: TqGetStandard300x250Ad("TQ_Mobile_HP_MID_300x250"),
  tq_mobile_home_mid2: TqGetStandard300x250Ad("TQ_Mobile_HP_BTF_300x250"),
  tq_mobile_home_stickey: get320x50stickyAd("TQ_Mobile_Sticky_320x50"),
  tq_mobile_section_topAd: getTopMobileAd("TQ_Mobile_SP_Top_320x100"),
  tq_mobile_section_mid1: TqGetStandard300x250Ad("TQ_Mobile_SP_ATF_300x250"),
  tq_mobile_section_mid2: TqGetStandard300x250Ad("TQ_Mobile_SP_MID_300x250"),
  tq_mobile_section_mid3: TqGetStandard300x250Ad("TQ_Mobile_SP_BTF_300x250"),
  tq_mobile_section_stickey: get320x50stickyAd("TQ_Mobile_Sticky_320x50"),
  tq_desktop_home_stickey: getStandard728x90Ad("TQ_Desktop_Sticky_728x90"),
  tq_desktop_article_stickey: getStandard728x90Ad("TQ_Desktop_Sticky_728x90"),
  tq_desktop_section_stickey: getStandard728x90Ad("TQ_Desktop_Sticky_728x90"),
  tq_desktop_home_out_of_page_1: get1x1Ad("TQ21_Out_of_page_1x1_A"),
  tq_desktop_home_out_of_page_2: get1x1Ad("TQ21_Out_of_page_1x1_B"),
  tq_desktop_home_mcanvas: get1x1Ad("TQ_Mcanvas_1x1"),
  tq_desktop_home_vidoomy: get1x1Ad("TQ_Video_1x1"),
};

const HQ_CONFIG = {
  hq_desktop_home_topAd: get970x90ResponsiveAd("HQ21_HP_Sec_ATF_Top_LB_Desk_970x90"),
  hq_desktop_home_rightATF: getStandard300x250Ad("HQ21_HP_ATF_Prem_300x250"),
  hq_desktop_home_home300x600: get300x600Ad("HQ21_HP_ATF_300x600"),
  hq_desktop_home_rightATF2: getStandard300x250Ad("HQ21_HP_ATF_Mid_300x250"),
  hq_desktop_section_topAd: get970x90ResponsiveAd("HQ21_HP_Sec_ATF_Top_LB_Desk_970x90"),
  hq_desktop_section_rightATF: getStandard300x250Ad("HQ21_Sec_ATF_Prem_300x250"),
  hq_desktop_section_rightATF2: getStandard300x250Ad("HQ21_Sec_ATF_Mid_300x250"),
  hq_desktop_article_storyTopAd: get970x90ResponsiveAd("HQ21_ROS_ATF_Top_LB_Desk_970x90"),
  hq_desktop_article_rightATF: getStandard300x250Ad("HQ21_ROS_ATF_Prem_300x250"),
  hq_desktop_article_rightstory2: getStandard300x250Ad("HQ21_ROS_ATF_Mid_300x250"),
  hq_desktop_article_content1: getStandard728x90Ad("HQ21_ROS_ATF_Mid_728x90"),
  hq_desktop_article_content2: getStandard728x90Ad("HQ21_ROS_BTF_Low_728x90"),
  hq_desktop_article_photos: getStandard728x90Ad("HQ_Desktop_Photo_Story_Ad"),
  hq_mobile_article_storyTopAd_mobile: getStandard300x250Ad("HQ21_ROS_ATF_Prem_300x250"),
  hq_mobile_article_mid1: getStandard300x250Ad("HQ21_ROS_ATF_Mid_300x250"),
  hq_mobile_article_mid2: getStandard300x250Ad("HQ21_ROS_BTF_300x250"),
  hq_mobile_article_stickey: get320x50stickyAd("HQ21_ROS_Sticky_320x50"),
  hq_mobile_article_photos: getStandard300x250Ad("HQ_Mobile_Photo_Story_Ad"),
  hq_mobile_home_topAd: getHomeTopMobileAd("HQ21_HP_Sec_ATF_Top_LB_Mob_320x100"),
  hq_mobile_home_mid1: getStandard300x250Ad("HQ21_HP_ATF_Prem_300x250"),
  hq_mobile_home_mid2: getStandard300x250Ad("HQ21_HP_ATF_Mid_300x250"),
  hq_mobile_home_stickey: get320x50stickyAd("HQ21_ROS_Sticky_320x50"),
  hq_mobile_section_topAd: getHomeTopMobileAd("HQ21_HP_Sec_ATF_Top_LB_Mob_320x100"),
  hq_mobile_section_mid1: getStandard300x250Ad("HQ21_Sec_ATF_Prem_300x250"),
  hq_mobile_section_mid2: getStandard300x250Ad("HQ21_Sec_ATF_Mid_300x250"),
  hq_mobile_section_mid3: getStandard300x250Ad("HQ21_Sec_BTF_300x250"),
  hq_mobile_section_stickey: get320x50stickyAd("HQ21_ROS_Sticky_320x50"),
  hq_desktop_home_stickey: getStandard728x90Ad("HQ_Desktop_Sticky_728x90"),
  hq_desktop_article_stickey: getStandard728x90Ad("HQ_Desktop_Sticky_728x90"),
  hq_desktop_section_stickey: getStandard728x90Ad("HQ_Desktop_Sticky_728x90"),
  hq_desktop_home_out_of_page_1: get1x1Ad("HQ21_Out_of_page_1x1"),
  hq_desktop_home_out_of_page_2: get1x1Ad("TQ21_Out_of_page_1x1_B"),
  hq_desktop_home_mcanvas: get1x1Ad("TQ_Mcanvas_1x1"),
};

const FIT_CONFIG = {
  fit_desktop_home_topAd: get970x90ResponsiveAd("FIT21_HP_Sec_ATF_Top_LB_Desk_970x90"),
  fit_desktop_home_rightATF: getStandard300x250Ad("FIT21_HP_ATF_Prem_300x250"),
  fit_desktop_home_home300x600: get300x600Ad("FIT21_HP_ATF_300x600"),
  fit_desktop_home_rightATF2: getStandard300x250Ad("FIT21_HP_ATF_Mid_300x250"),
  fit_desktop_section_topAd: get970x90ResponsiveAd("FIT21_HP_Sec_ATF_Top_LB_Desk_970x90"),
  fit_desktop_section_rightATF: getStandard300x250Ad("FIT21_Sec_ATF_Prem_300x250"),
  fit_desktop_section_rightATF2: getStandard300x250Ad("FIT21_Sec_ATF_Mid_300x250"),
  fit_desktop_article_storyTopAd: get970x90ResponsiveAd("FIT21_ROS_ATF_Top_LB_Desk_970x90"),
  fit_desktop_article_rightATF: getStandard300x250Ad("FIT21_ROS_ATF_Prem_300x250"),
  fit_desktop_article_rightstory2: getStandard300x250Ad("FIT21_ROS_ATF_Mid_300x250"),
  fit_desktop_article_content1: getStandard728x90Ad("FIT21_ROS_ATF_Mid_728x90"),
  fit_desktop_article_content2: getStandard728x90Ad("FIT21_ROS_BTF_Low_728x90"),
  fit_mobile_article_storyTopAd_mobile: getStandard300x250Ad("FIT21_ROS_ATF_Prem_300x250"),
  fit_mobile_article_mid1: getStandard300x250Ad("FIT21_ROS_ATF_Mid_300x250"),
  fit_mobile_article_mid2: getStandard300x250Ad("FIT21_ROS_BTF_300x250"),
  fit_mobile_article_stickey: get320x50stickyAd("FIT21_ROS_Sticky_320x50"),
  fit_mobile_home_topAd: getHomeTopMobileAd("FIT21_HP_Sec_ATF_Top_LB_Mob_320x100"),
  fit_mobile_home_mid1: getStandard300x250Ad("FIT21_HP_ATF_Prem_300x250"),
  fit_mobile_home_mid2: getStandard300x250Ad("FIT21_HP_ATF_Mid_300x250"),
  fit_mobile_home_stickey: get320x50stickyAd("FIT21_HP&Sec_Sticky_320x50"),
  fit_mobile_section_topAd: getHomeTopMobileAd("FIT21_HP_Sec_ATF_Top_LB_Mob_320x100"),
  fit_mobile_section_mid1: getStandard300x250Ad("FIT21_Sec_ATF_Prem_300x250"),
  fit_mobile_section_mid2: getStandard300x250Ad("FIT21_Sec_ATF_Mid_300x250"),
  fit_mobile_section_mid3: getStandard300x250Ad("FIT21_Sec_BTF_300x250"),
  fit_mobile_section_stickey: get320x50stickyAd("FIT21_HP&Sec_Sticky_320x50"),
  fit_desktop_home_out_of_page_1: get1x1Ad("FIT21_Out_of_page_1x1"),
};

const CONFIG = {
  ...TQ_CONFIG,
  ...HQ_CONFIG,
  ...FIT_CONFIG,
};

// const DfpAd = createDfpAdComponent({
//   defaultNetworkID: "22203938680",
//   config: CONFIG,
//   targeting: function (state, props) {
//     const currentPath = get(state, ["qt", "currentPath"], "/");

//     const params = {
//       environment: get(state, ["qt", "config", ["publisher-attributes"], "env"]),
//       pageType: get(state, ["qt", "pageType"]),
//       publisherName: get(state, ["qt", "config", "publisher-name"]),
//       publisherId: get(state, ["qt", "config", "publisher-id"]) || 1,
//       theme: props.isDarkTheme ? "dark" : "white",
//     };

//     if (get(state, ["qt", "pageType"]) === "home-page" && currentPath.includes("/fit")) {
//       params.section = get(state, ["qt", "data", "collection", "metadata", "section", 0, "name"], "");
//       params.sectionName = get(state, ["qt", "data", "collection", "metadata", "section", 0, "name"], "");
//     }

//     if (get(state, ["qt", "pageType"]) === "section-page") {
//       params.section = get(state, ["qt", "data", "collection", "metadata", "section", 0, "name"], "");
//       params.sectionName = get(state, ["qt", "data", "collection", "metadata", "section", 0, "name"], "");
//     }

//     if (get(state, ["qt", "pageType"]) === "story-page") {
//       const story = get(props, ["story"], get(state, ["qt", "data", "story"], {}));
//       const storySections = get(props, ["story", "sections"], []);
//       const sectionList = [];
//       storySections && storySections.map((section) => sectionList.push(section.name));

//       params.Contextual_Article = get(story, ["seo", "meta-keywords"], []);
//       params.sectionName = sectionList.length && sectionList;
//     }

//     if (get(state, ["qt", "pageType"]) === "story-page" && get(props, ["story", "metadata", "sponsored-by"])) {
//       params.sponsor = get(props, ["story", "metadata", "sponsored-by"]);
//     }

//     return params;
//   },
// });

/**
 * Delaying DFP Component by 5000ms initially, In order to reduce initial load time for Performance.
 * Once the delay is finished, then Component will render on the UI.
 * Then upon subseqent Navigations the Delay will be .5second
 */

const DfpAd = ({ ...props }) => {
  // const [delayComponentRender, setDelayComponentRender] = useState(false);
  // const delay = props.removeAdDelay === "True" ? 0 : 500;

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setDelayComponentRender(() => {
  //       return true;
  //     });
  //   }, delay);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  const networkID = "22203938680/";
  const adtype = props.adtype;
  if (!CONFIG[adtype]) {
    return null;
  }
  const randomNum = Math.floor(Math.random() * 10000);
  const path = networkID + CONFIG[adtype].adUnit;
  const size = CONFIG[adtype].sizes;
  const id = `22203938680/${CONFIG[adtype].adUnit}`;
  const env = props.env;
  const publisherName = props.publisherName;
  const pageType = props.pageType;
  const isDarkTheme = props.isDarkTheme;
  let slotId = `${id}_${randomNum}`;
  if (adtype === "tq_desktop_home_out_of_page_1" || adtype === "tq_desktop_home_out_of_page_2") {
    slotId = `${id}`;
  }

  useEffect(() => {
    getAdSlots({
      path,
      size,
      id,
      env,
      publisherName,
      pageType,
      isDarkTheme,
      slotId,
      ...props,
    });
  }, [id]);
  // return <div>{delayComponentRender ? <DfpAdContent {...props} /> : null}</div>;

  return <div id={slotId}></div>;
};

DfpAd.propTypes = {
  removeAdDelay: bool,
  adtype: string,
  pageType: string,
  env: string,
  publisherName: string,
  isDarkTheme: bool,
};

export default DfpAd;
