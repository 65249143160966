import React from "react";
import { number } from "prop-types";

const SearchIcon = ({ width = 36, height = 36 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 42 42">
    <path d="M0 0h42v42H0z" fill="rgba(255,255,255,0)" />
    <path
      d="M26.472 23.655h-1.484l-.526-.507a12.224 12.224 0 1 0-1.314 1.314l.507.526v1.483l7.524 7.505 2.8-2.8zm-11.266 0a8.45 8.45 0 1 1 8.45-8.45 8.438 8.438 0 0 1-8.451 8.45z"
      fill="#fff"
      transform="translate(2.526 2.525)"
    />
  </svg>
);

SearchIcon.propTypes = {
  width: number,
  height: number,
};

export default SearchIcon;
