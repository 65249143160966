/* eslint-disable camelcase */
import { generateJwtToken, createCashfreeOrder } from "./api";

const cancelSubscription = (id) =>
  global.AccessType ? global.AccessType.cancelSubscription(id) : console.error("AccessType not loaded!");

const getAllPlans = () =>
  global.AccessType ? global.AccessType.getSubscriptionPlans() : console.error("AccessType not loaded!");

const getMyPlans = () =>
  global.AccessType ? global.AccessType.getSubscriptions() : console.error("AccessType not loaded!");

const getPaymentOptions = () => global.AccessType.getPaymentOptions();

const getAccessTypeUrl = (accountKey, env) => {
  const isStaging = env === "staging";
  const sandbox = isStaging || env === "beta" ? "&env=sandbox" : "";

  return `https:///www.accesstype.com/frontend/v2/accesstype.js?key=${accountKey}${sandbox}`;
};

const loadAccessType = (accountKey, env, callback) => {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.onload = () => callback();
  script.src = getAccessTypeUrl(accountKey, env);
  document.getElementsByTagName("head")[0].appendChild(script);
};

const setAccessTypeUser = (email, mobile, id = null, name = null) => {
  return generateJwtToken(email, id, name).then((token) => {
    return global.AccessType.setUser({
      emailAddress: email,
      mobileNumber: mobile,
      accesstypeJwt: token,
    }).catch((error) => console.log(error));
  });
};

const downloadInvoice = (subscriptionId, invoiceId) => {
  return global.AccessType
    ? global.AccessType.downloadInvoice(subscriptionId, invoiceId)
    : console.error("AccessType not loaded!");
};
const getRazorpayOptions = (method) => {
  let options = {};
  const upiOptions = {
    config: {
      display: {
        blocks: {
          banks: {
            name: "All payment methods",
            instruments: [
              {
                method: "upi",
                flows: ["collect"],
              },
            ],
          },
        },
        hide: [
          {
            method: "card",
          },
        ],
        sequence: ["block.banks"],
        preferences: {
          show_default_blocks: false,
        },
      },
    },
  };

  const cardOptions = {
    config: {
      display: {
        blocks: {
          banks: {
            name: "All payment methods",
            instruments: [
              {
                method: "card",
              },
            ],
          },
        },
        hide: [
          {
            method: "upi",
          },
        ],
        sequence: ["block.banks"],
        preferences: {
          show_default_blocks: false,
        },
      },
    },
  };
  const upiAndCards = {
    config: {
      display: {
        blocks: {
          banks: {
            name: "All payment methods",
            instruments: [
              {
                method: "upi",
                flows: ["collect"],
              },
              {
                method: "card",
                flows: ["collect"],
              },
            ],
          },
        },
        hide: [],
        sequence: ["block.banks"],
        preferences: {
          show_default_blocks: false,
        },
      },
    },
  };
  const netBankingOptions = {
    config: {
      display: {
        blocks: {
          banks: {
            name: "All payment methods",
            instruments: [
              {
                method: "netbanking",
              },
              {
                method: "upi",
                flows: ["collect"],
              },
              {
                method: "card",
              },
            ],
          },
        },
        sequence: ["block.banks"],
        preferences: {
          show_default_blocks: true,
        },
      },
    },
  };

  if (method === "upi") {
    options = upiOptions;
  } else if (method === "card") {
    options = cardOptions;
  } else if (method === "upi-card") {
    options = upiAndCards;
  } else if (method === "netbanking") {
    options = netBankingOptions;
  } else {
    options = {};
  }

  return options;
};

// RazorPay Payment
const makeRazorpayPayment = (currentPlan, paymentOptions, paymentMethod) => {
  const options = getRazorpayOptions(paymentMethod);
  const { id, price_cents, price_currency, title, recurring } = currentPlan;
  const subscriptionParams = {
    type: "standard",
    plan: {
      id,
      price_cents,
      price_currency,
      title,
    },
    payment: {
      payment_type: recurring ? "razorpay_recurring" : "razorpay",
      amount_cents: price_cents,
      amount_currency: price_currency,
    },
    options,
  };
  return paymentOptions.razorpay.proceed(subscriptionParams);
};

export const giftSubscriptionRazorpayPayment = (
  currentPlan,
  paymentOptions,
  paymentMethod,
  gifteeEmail,
  gifteeName,
  personalNote,
  gifterEmail
) => {
  const options = getRazorpayOptions(paymentMethod);
  const { id, price_cents, price_currency, title, recurring } = currentPlan;
  const subscriptionParams = {
    type: "standard",
    plan: {
      id,
      price_cents,
      price_currency,
      title,
    },
    payment: {
      payment_type: recurring ? "razorpay_recurring" : "razorpay",
      amount_cents: price_cents,
      amount_currency: price_currency,
    },
    recipient_subscriber: {
      recipient_identity_provider: "email",
      recipient_identity: gifteeEmail,
    },
    metadata: {
      GifteeName: gifteeName,
      GifterID: gifterEmail,
      GifteeNote: personalNote || "",
    },
    options,
  };
  return paymentOptions.razorpay.proceed(subscriptionParams);
};
// Cashfree Paymemnt
const makeCashfreePayment = (paymentFor, plan, member, setLoading) => {
  if (member) {
    setLoading(true);
    const orderData = {
      id: member.id,
      name: member.name,
      email: member.email,
      phone: member?.metadata?.userData?.mobileNumber || "1234567890",
      amount: plan.price_cents / 100,
      currency: plan.price_currency,
      plan_id: plan.id,
      redirection_url: window.location.href.split("?")[0],
      payment_for: paymentFor,
    };
    createCashfreeOrder(orderData)
      .then((sessionID) => {
        // eslint-disable-next-line no-undef
        const cashfree = new Cashfree(sessionID);
        cashfree.redirect();
      })
      .catch((error) => {
        console.log(error, "cashfree-create-order-error");
      })
      .finally(() => {
        setLoading(false);
      });
  } else {
    console.error("something went wrong with selected plan!", member);
  }
};

export const makeStripePayment = (currentPlan, paymentOptions, planType, successUrl, cancelUrl) => {
  // eslint-disable-next-line camelcase
  const { id, price_cents, price_currency, title } = currentPlan;
  const subscriptionParams = {
    type: "standard",
    plan: {
      id,
      price_cents,
      price_currency,
      title,
    },
    payment: {
      payment_type: planType,
      amount_cents: price_cents,
      amount_currency: price_currency,
    },
    options: {
      urls: {
        success_url: successUrl,
        cancel_url: cancelUrl,
      },
    },
  };
  console.log("paymentOptions", paymentOptions);
  return paymentOptions.stripe && paymentOptions.stripe.proceed(subscriptionParams);
};

export const giftSubscriptionStripePayment = (
  currentPlan,
  paymentOptions,
  planType,
  successUrl,
  cancelUrl,
  gifteeEmail,
  gifteeName,
  personalNote,
  gifterEmail
) => {
  // eslint-disable-next-line camelcase
  const { id, price_cents, price_currency, title } = currentPlan;
  const subscriptionParams = {
    type: "standard",
    plan: {
      id,
      price_cents,
      price_currency,
      title,
    },
    payment: {
      payment_type: planType,
      amount_cents: price_cents,
      amount_currency: price_currency,
    },
    recipient_subscriber: {
      recipient_identity_provider: "email",
      recipient_identity: gifteeEmail,
    },
    metadata: {
      GifteeName: gifteeName,
      GifterID: gifterEmail,
      GifteeNote: personalNote || "",
    },
    options: {
      urls: {
        success_url: successUrl,
        cancel_url: cancelUrl,
      },
    },
  };
  return paymentOptions.stripe && paymentOptions.stripe.proceed(subscriptionParams);
};

export const makePaypalPayment = (currentPlan, paymentOptions, planType, type) => {
  // eslint-disable-next-line camelcase
  const { id, price_cents, price_currency, title } = currentPlan;
  const subscriptionParams = {
    type: "standard",
    plan: {
      id,
      price_cents,
      price_currency,
      title,
    },
    payment: {
      payment_type: planType,
      amount_cents: price_cents,
      amount_currency: price_currency,
    },
    options: {
      urls: {
        return_url: `${window.location.origin}/${type}/purchase_successful`,
        cancel_url: `${window.location.origin}/${type}/purchase_failed`,
      },
    },
  };

  return (
    paymentOptions.paypal &&
    paymentOptions.paypal.proceed(subscriptionParams).then((res) => res && res.proceed(subscriptionParams))
  );
};
export const giftSubscriptionPlaypalPayment = (
  currentPlan,
  paymentOptions,
  planType,
  type,
  gifteeEmail,
  gifteeName,
  personalNote,
  gifterEmail
) => {
  const { id, price_cents, price_currency, title } = currentPlan;
  const subscriptionParams = {
    type: "standard",
    plan: {
      id,
      price_cents,
      price_currency,
      title,
    },
    payment: {
      payment_type: planType,
      amount_cents: price_cents,
      amount_currency: price_currency,
    },
    recipient_subscriber: {
      recipient_identity_provider: "email",
      recipient_identity: gifteeEmail,
    },
    metadata: {
      GifteeName: gifteeName,
      GifterID: gifterEmail,
      GifteeNote: personalNote || "",
    },
    options: {
      urls: {
        return_url: `${window.location.origin}/${type}/purchase_successful`,
        cancel_url: `${window.location.origin}/${type}/purchase_failed`,
      },
    },
  };

  return (
    paymentOptions.paypal &&
    paymentOptions.paypal.proceed(subscriptionParams).then((res) => res && res.proceed(subscriptionParams))
  );
};

export {
  getAllPlans,
  getMyPlans,
  setAccessTypeUser,
  makeRazorpayPayment,
  cancelSubscription,
  getPaymentOptions,
  loadAccessType,
  makeCashfreePayment,
  downloadInvoice,
};
