/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import TheQuintLogo from "../../atoms/icons/the-quint-logo";
import QuintHindiLogo from "../../atoms/icons/quint-hindi-logo";
import { connect } from "react-redux";
import get from "lodash/get";
import { object, func, string, bool, number } from "prop-types";
import { WithGoogleLogin } from "@quintype/components";
import { moengageTracking, getMobileNumber } from "../../utils/utils";
import { addSignUpLoginToDLNew } from "../../helper/datalayer-utils";
import { setAccessTypeUser } from "../../helper/access-type";
import { setLoading, setMember, setMakePayment } from "../../helper/actions";
import translate from "../../translate";
import EmailAuth from "./email-auth";
import LoginShimmer from "./login-shimmer";

import "./new-native-login.m.css";
import { checkExistingUser, saveNewsletter } from "../../helper/api";
import { setCookie } from "../../helper/cookies";

const NewNativeLogin = ({
  googleClientId,
  facebookId,
  member,
  setMember,
  setLoading,
  closePopup,
  setupPlans,
  setMakePayment,
  postLoginPayment,
  publisherName,
  id,
  token,
  logInFrom,
}) => {
  const [signIn, setSignIn] = useState(false);
  const [showShimmer, setShowShimmer] = useState(false);
  const [additionalButton, setAdditionalButton] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("https://www.thequint.com");
  const [error, setError] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state value
  };

  function onScriptLoaded(clientId, scope) {
    global.gapi.load("client:auth2", function () {
      return global.gapi.client.init({
        clientId: clientId,
        scope: scope,
      });
    });
  }
  useEffect(() => {
    const location = new URL(window.location.href);
    setRedirectUrl(`${location.origin}${location.pathname}`);
    global.gapi && onScriptLoaded(googleClientId, "email");
  }, []);

  useEffect(() => {
    setShowShimmer(true);
    setTimeout(() => {
      setShowShimmer(false);
    }, [1000]);
  }, [signIn]);

  const getLogo = () => {
    if (publisherName === "quint-hindi") {
      return (
        <div className="header-hq-logo" styleName="hindi-color-logo">
          <QuintHindiLogo width={130} height={46} color="var(--twentythree-color)" />
        </div>
      );
    } else
      return (
        <div styleName="english-quint-logo">
          <TheQuintLogo width={74} height={46} color="#000" />
        </div>
      );
  };

  const chooseSignUp = () => {
    return (
      <>
        <div styleName="sign-in-wrapper">
          <div styleName={`sign-in-button`}>
            <button
              onClick={() => {
                setSignIn(true);
                setAdditionalButton(false);
                setLogInType("");
              }}
            >
              {translate(publisherName, "login")}
            </button>
          </div>
          <div styleName={`sign-in-button `}>
            <button
              onClick={() => {
                setSignIn(false);
                setLogInType("");
              }}
            >
              {translate(publisherName, "register")}
            </button>
          </div>
        </div>
        <div styleName="selected-line">
          <div styleName={`${signIn ? "selected-sign-in" : ""}`}></div>
          <div styleName={`${!signIn ? "selected-sign-up" : ""}`}></div>
        </div>
      </>
    );
  };

  function setUserOnAT(member) {
    const { email, id } = member;

    const mobileNumber = getMobileNumber(member);
    const name = member["first-name"] || member.name;
    setupPlans && setupPlans(member, "hit");
    if (global.AccessType) {
      setAccessTypeUser(email, mobileNumber, id, name)
        .then(() => {
          closePopup();
          postLoginPayment && setMakePayment(true);
        })
        .catch((e) => {
          console.error("err setting AT user:-", e);
        })
        .finally((resp) => {
          setLoading(false);
          console.log("resp:>:>:>:>:>:>", resp);
        });
    } else {
      setLoading(false);
      closePopup();
    }
  }

  const socialLogin = async (e, login) => {
    e.preventDefault();
    onScriptLoaded(googleClientId, "email");
    setLoading(true);
    if (!global.gapi || !global.gapi.client) {
      global.gapi && onScriptLoaded(googleClientId, "email");
    }
    try {
      const { member } = await login();
      closePopup();
      setMember(member);
      setUserOnAT(member);
      setLoading(false);
      moengageTracking("registration", member);
      if (isChecked && !signIn) {
        const url = window.location.href;
        const body = {
          email: member.email,
          subscriptionStatus: "Subscribed",
          referredUrl: url,
          publisher: publisherName,
        };
        saveNewsletter(body);
      }

      checkExistingUser(member.email)
        .then((response) => {
          if (response.exists) {
            addSignUpLoginToDLNew("login", member, window.location.pathname, logInFrom);
          } else {
            // If User is a returning user, don't add Registered tracking on FB Pixel
            addSignUpLoginToDLNew("sign_up", member, window.location.pathname, logInFrom);
          }
        })
        .catch((error) => {
          console.error("checkExistingUser error", error);
          if (signIn) {
            addSignUpLoginToDLNew("login", member, window.location.pathname, logInFrom);
          } else {
            addSignUpLoginToDLNew("sign_up", member, window.location.pathname, logInFrom);
          }
        });
    } catch (error) {
      console.error("social login error", error);
      setError(true);
      setLoading(false);
    }
  };
  const googleAuth = () => {
    return (
      <div styleName={!additionalButton ? "auth-wrapper" : ""}>
        <WithGoogleLogin clientId={googleClientId} scope="email" emailMandatory>
          {({ login, serverSideLoginPath }) => (
            <a
              href={serverSideLoginPath}
              onClick={(e) => socialLogin(e, login)}
              styleName={!additionalButton ? "google-wrapper" : ""}
            >
              <img
                src="https://media.assettype.com/thequint/2023-06/2d1a01b4-dd62-439d-ae2f-5bac527891e2/google_login_icon.svg"
                style={{ width: "35px", height: "35px" }}
                alt="Google Login Icon"
              />
              {!additionalButton && <span>{translate(publisherName, "google_login")}</span>}
            </a>
          )}
        </WithGoogleLogin>
      </div>
    );
  };

  const emailAuth = (logIn) => {
    return (
      <div
        styleName="auth-wrapper-mail"
        onClick={() => {
          setLogInType("email");
          setAdditionalButton(logIn !== "logIn");
        }}
      >
        <img
          src="https://media.assettype.com/thequint/2023-06/3d739455-b52d-48dc-ad08-9c63f91df4e3/email_login_icon.svg"
          alt="Email Login Icon"
        />
        <span>{translate(publisherName, "email_login")}</span>
      </div>
    );
  };

  const appleLogin = () => {
    if (isChecked && !signIn) {
      setCookie("apple-signup-with-newsletter", "subscribed", 600);
    }
    window.location.href = `${window.location.origin}/api/auth/v1/login?auth-provider=apple&redirect-url=${redirectUrl}`;
  };

  const appleAuth = () => {
    return (
      <div styleName={!additionalButton ? "auth-wrapper" : "apple-default-wrapper"}>
        <a onClick={appleLogin} styleName={!additionalButton ? "apple-wrapper" : ""}>
          <img
            src="https://media.assettype.com/thequint/2023-06/4a3367e4-98ef-44f5-82d7-0c10ca2dfed4/apple_login_icon.svg"
            style={{ width: "20px", height: "24px" }}
            alt="Apple Login Icon"
          />
          {!additionalButton && <span>{translate(publisherName, "apple_login")}</span>}
        </a>
      </div>
    );
  };

  const topHeader = () => {
    return (
      <div>
        {getLogo()}
        {chooseSignUp()}
      </div>
    );
  };

  function closeModal() {
    closePopup();
  }

  const authContainer = () => {
    return (
      <div styleName="auth-container">
        {showShimmer ? (
          <LoginShimmer />
        ) : (
          <>
            {error && <p style={{ textAlign: "center", color: "red" }}>Something went wrong please try again</p>}
            {googleAuth()}
            {appleAuth()}
            {signIn && logInType === "email" && (
              <p styleName="email-partition">
                <span>{translate(publisherName, "email_login")}</span>
              </p>
            )}
            {logInType !== "email" ? (
              signIn ? (
                emailAuth("logIn")
              ) : (
                emailAuth()
              )
            ) : (
              <EmailAuth
                closePopup={closePopup}
                setupPlans={setupPlans}
                signIn={signIn}
                publisherName={publisherName}
                id={id}
                token={token}
                postLoginPayment={postLoginPayment}
                setLoading={setLoading}
                setMember={setMember}
                setMakePayment={setMakePayment}
                logInFrom={logInFrom}
                isChecked={isChecked}
              />
            )}
            {!signIn && (
              <div styleName="checkbox-wrapper">
                <label>
                  <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                  <span> {translate(publisherName, "daily_newsletter")}</span>
                </label>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const getLoginscreen = () => {
    if (logInType === "email" && !signIn) {
      return (
        <EmailAuth
          closePopup={closePopup}
          setupPlans={setupPlans}
          signIn={signIn}
          publisherName={publisherName}
          id={id}
          token={token}
          postLoginPayment={postLoginPayment}
          setLoading={setLoading}
          setMember={setMember}
          setMakePayment={setMakePayment}
          logInFrom={logInFrom}
          isChecked={isChecked}
        />
      );
    } else if (logInType === "phone") {
      return <div>Phone</div>;
    } else return authContainer();
  };

  const getDefaultSignUp = () => {
    return (
      <div styleName="default-auth">
        <span>{translate(publisherName, "unable_to_login")}</span>
        <div styleName="default-auth-container">
          {googleAuth()}
          {appleAuth()}
        </div>
      </div>
    );
  };

  const [logInType, setLogInType] = useState();
  return (
    <div styleName="sign-up-container">
      <div styleName="container">
        <span styleName="close-btn" onClick={closeModal}>
          X
        </span>
        {topHeader()}
        {getLoginscreen()}
        {additionalButton && getDefaultSignUp()}
        <div styleName="benefits-wrapper">
          <div styleName="benefits-text">
            <h3>{translate(publisherName, "benefits")}</h3>
            <p>
              <span>&#x2022;</span> {translate(publisherName, "recommended")}
            </p>
            <p>
              <span>&#x2022;</span> {translate(publisherName, "bookmark_articles")}
            </p>
          </div>
          <div styleName="privacy-policy">
            <p>
              {translate(publisherName, "declaration_1")} <a href="/terms-and-conditions">T&C</a>{" "}
              {translate(publisherName, "and")}{" "}
              <a href="/privacy-policy">{translate(publisherName, "declaration_2")}</a>{" "}
              {translate(publisherName, "declaration_3")}
            </p>
          </div>
        </div>
      </div>
      <div styleName="right-side">
        <img
          src="https://media.assettype.com/thequint/2023-09/1927fe66-e269-44dd-bf47-4381b66a144d/because_the_news_is_worth_it.png"
          style={{ width: "344px" }}
        />
      </div>
    </div>
  );
};

NewNativeLogin.propTypes = {
  googleClientId: string,
  facebookId: number,
  member: object,
  setMember: func,
  setLoading: func,
  closePopup: func,
  setMakePayment: func,
  postLoginPayment: bool,
  setupPlans: func,
  publisherName: string,
  id: string,
  token: string,
  logInFrom: string,
};

const mapDispatchToProps = (dispatch) => ({
  setMember: (member) => dispatch(setMember(member)),
  setLoading: (isLoading) => dispatch(setLoading(isLoading)),
  setMakePayment: (value) => dispatch(setMakePayment(value)),
});

const mapStateToProps = (state) => {
  const item = get(state, ["qt", "config", "publisher-attributes", "mailchimp"], {});
  return {
    googleClientId: get(state, ["qt", "config", "publisher-attributes", "socialAuth", "google"], ""),
    facebookId: get(state, ["qt", "config", "publisher-attributes", "socialAuth", "fb"], ""),
    member: state.member,
    postLoginPayment: get(state, ["userReducer", "postLoginPayment"], null),
    publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null),
    isDarkTheme: get(state, ["settingsReducer", "isDarkTheme"], false),
    logInFrom: get(state, ["userReducer", "logInFrom"], null),
    id: item.listId,
    token: item.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewNativeLogin);
