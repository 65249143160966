import React from "react";
import { number } from "prop-types";

const HamburgerIcon = ({ width }) => (
  <svg width={width} height={width} viewBox="0 0 26 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2H8" stroke="currentColor" strokeWidth="2.52778" strokeLinecap="round" />
    <path d="M2 8.01562L8.19231 8.07782" stroke="currentColor" strokeWidth="2.52778" strokeLinecap="round" />
    <path d="M2.06543 14.1719L10.8459 14.2686" stroke="currentColor" strokeWidth="2.52778" strokeLinecap="round" />
    <path
      d="M24.8381 13.6042L21.4662 10.2282C23.166 7.94494 22.9728 4.68871 20.8867 2.6192C19.7355 1.46676 18.2521 0.9 16.7496 0.9C15.2471 0.9 13.7635 1.4668 12.6124 2.61932C10.3292 4.90527 10.3292 8.61736 12.6124 10.9033C13.7635 12.0558 15.2471 12.6226 16.7496 12.6226C17.97 12.6226 19.1915 12.2493 20.2128 11.4834L23.6033 14.8402C23.6033 14.8403 23.6034 14.8403 23.6035 14.8404C23.776 15.013 23.9933 15.1 24.2303 15.1C24.4469 15.1 24.6839 15.0138 24.8573 14.8402C25.1856 14.5115 25.1824 13.9489 24.8381 13.6042ZM16.7688 10.8671C15.6645 10.8671 14.6537 10.4366 13.866 9.66692C12.2753 8.07384 12.2748 5.46783 13.8666 3.85509C14.6343 3.08661 15.6637 2.65556 16.7688 2.65556C17.8732 2.65556 18.8842 3.08623 19.672 3.85615C20.4573 4.6237 20.8695 5.65369 20.8695 6.76132C20.8695 7.8672 20.4393 8.87948 19.6703 9.66819C18.9038 10.4543 17.856 10.8671 16.7688 10.8671Z"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
);

HamburgerIcon.propTypes = {
  width: number,
};

export default HamburgerIcon;
